<div class="container px-0">
  <!-- Header -->
  <header
    class="bg-white text-white d-flex align-items-center justify-content-start"
    style="height: 75px"
  >
    <div class="col-auto">
      <img
        [src]="infoLanding?.logo?.large"
        alt="Logo"
        height="43"
        class="logo"
      />
    </div>
  </header>
</div>

<div class="container my-5">
  <div class="d-flex flex-column flex-md-row container-card">
    <div class="col-md-7 description-column order-2 order-md-1 pl-md-0">
      <div class="col-md-11 px-0">
        <div
          class="text-primary"
          [innerHTML]="
            this.sanitizer.bypassSecurityTrustHtml(infoLanding.title)
          "
        ></div>
      </div>
      <div class="d-flex align-items-center" *ngFor="let item of featuredItems">
        <div *ngIf="item.title" class="col-auto px-0 my-2">
          <img
            src="/assets/images/landing/checkbox-circle-fill.png"
            alt="Logo"
            height="20"
            width="20"
          />
        </div>
        <div *ngIf="item.title" class="col-auto" style="font-size: 18px">
          {{ item.title }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-auto col-12">
          <button class="btn btn-primary mt-3">
            {{ infoLanding?.data_json?.purchase_button_text }}
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-8 image-column px-0 order-1 order-md-2">
      <img
        [src]="infoLanding?.background_image_01.large"
        alt="Imagen del Producto"
        class="img-fluid img-principal"
      />
    </div>
  </div>
</div>

<div class="container">
  <div class="d-flex justify-content-center my-4">
    <h2>{{ infoLanding.data_json.sales_title }}</h2>
  </div>
  <div class="d-flex justify-content-center">
    <div
      class="col-md-7 col-12 p-4"
      style="
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
      "
    >
      <div
        class="d-flex align-items-start"
        *ngFor="let highlight of highlighted"
      >
        <div
          *ngIf="highlight.title && highlight.description"
          class="col-auto px-0 mt-1"
        >
          <img
            src="/assets/images/landing/checkbox-circle-fill.png"
            alt="Logo"
            height="25"
            width="25"
          />
        </div>
        <div class="col-auto" *ngIf="highlight.title && highlight.description">
          <p class="mb-0" style="color: #232233; font-size: 20px">
            {{ highlight.title }}
          </p>
          <p style="color: #6c6c72; font-size: 16px">
            {{ highlight.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-5">
  <div class="row justify-content-center my-4">
    <div class="col-auto">
      <h2 class="my-4">
        {{ infoLanding?.data_json?.learn_title }}
      </h2>
    </div>
    <div class="col-md-9 col-12 text-center">
      <p style="font-size: 18px">
        {{ infoLanding?.data_json?.learn_description }}
      </p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6 col-12 p-4" *ngFor="let item of learn">
      <div *ngIf="item.title" class="d-flex align-items-start">
        <div class="border-left-container">
          <h5 style="color: #000; font-size: 24px; font-weight: 700">
            {{ item.title }}
          </h5>
          <p style="color: #acacac; font-size: 16px">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-auto col-12">
      <button class="btn btn-primary mt-3">
        {{ infoLanding?.data_json?.purchase_button_text }}
      </button>
    </div>
  </div>
</div>

<div
  class="container background-container my-5"
  [ngStyle]="{
    'background-image':
      infoLanding?.background_image_02?.large &&
      !infoLanding.background_image_02.large.includes('generic.png')
        ? 'url(' + infoLanding.background_image_02.large + ')'
        : ''
  }"
>
  <h1 class="mt-5">{{ infoLanding?.data_json?.crossed_price_title }}</h1>
  <p class="price-extra">Usd {{ infoLanding?.data_json?.crossed_price }}</p>
  <h1>{{ infoLanding?.data_json?.final_price_title }}</h1>
  <p class="price-nounderline">
    Usd {{ infoLanding?.data_json?.today_only_price }}
  </p>
  <div class="row mt-4">
    <div class="col-md-auto col-12">
      <button class="btn btn-primary mt-3 px-5">
        {{ infoLanding?.data_json?.purchase_button_text }}
      </button>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-6 col-12">
      <img
        [src]="infoLanding?.background_image_03.large"
        alt="Imagen del Producto"
        class="img-fluid"
        style="
          max-height: 420px !important  ;
          min-width: 100%;
          object-fit: cover;
        "
      />
    </div>
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-center" *ngIf="highlights">
        <div class="col-12 p-4">
          <div
            class="d-flex align-items-start"
            *ngFor="let highlight of highlights"
          >
            <div *ngIf="highlight.title" class="col-auto px-0 my-2">
              <img
                src="/assets/images/landing/checkbox-circle-fill.png"
                alt="Logo"
                height="20"
                width="20"
              />
            </div>
            <div *ngIf="highlight.title" class="col-auto">
              <p style="color: #232233; font-size: 20px">
                {{ highlight.title }}
              </p>
              <p style="color: #6c6c72; font-size: 16px">
                {{ highlight.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row justify-content-center my-4 text-center">
    <div class="col-12 mx-auto text-center">
      <h2 class="my-4">{{ infoLanding?.data_json?.testimonials_title }}</h2>
    </div>

    <div class="col-md-9 col-12">
      <p style="font-size: 18px; color: #6c6c72">
        {{ infoLanding?.data_json?.testimonials_description }}
      </p>
    </div>
  </div>
  <div class="row" *ngIf="testimonials">
    <!-- Tarjeta 1 -->
    <div
      class="col-md-4"
      *ngFor="let testimonial of testimonials; let i = index"
    >
      <div class="card">
        <div
          class="container-img d-flex justify-content-center align-items-center"
        >
          <img
            [src]="testimonial?.image"
            alt="Perfil 1"
            class="profile-img card-img-top"
            style="border-radius: 108%"
          />
        </div>
        <div class="card-body text-center">
          <h5 class="card-title">{{ testimonial.name }}</h5>
          <app-stars [stars]="testimonial.stars"></app-stars>
          <p class="card-text">{{ testimonial.text }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <div class="col-md-auto col-12">
      <button class="btn btn-primary px-5">
        {{ infoLanding?.data_json?.purchase_button_text }}
      </button>
    </div>
  </div>
</div>

<div class="container my-5">
  <div class="row justify-content-center my-4">
    <div class="col-auto">
      <h2 class="my-4">PREGUNTAS FRECUENTES</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8 offset-lg-2">
      <app-faq-item
        *ngFor="let faq of faqs"
        [title]="faq.title"
        [collapsed]="faq.collapsed"
        (boxExpanded)="setAllClosed($event)"
        [content]="faq.content"
      >
      </app-faq-item>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <div class="col-md-auto col-12">
      <button class="btn btn-primary px-5">
        {{ infoLanding?.data_json?.purchase_button_text }}
      </button>
    </div>
  </div>
</div>
