<div class="courseItem">
  <div class="itemImage hover-shadow" (click)="goToProfile(product.id)">
    <picture>
      <source
        media="(max-width: 799px)"
        srcset="{{product?.hero_image.medium}}"
      />
      <img
        [src]="product?.hero_image.medium"
        onerror="this.src = '/assets/images/mootiva/logo-dark.png'"
      />
    </picture>
  </div>
  <div class="itemDetails">
    <p
      class="itemTitle hover-text-shadow textFont mb-2"
      (click)="goToProfile(product.id)"
      [innerHTML]="product?.name"
    ></p>
    <div class="row">
      <div class="col" *ngIf="product.price > 0; else free">
        <p
          class="text-left text-info font-weight-bold textFont"
          *ngIf="product.price && product.user_access == ''"
        >
          <span *ngIf="isDiscounted()">
            <span class="badge bg-extra text-light mr-2"
              >{{ "Discount" | translate }}!</span
            >
            <span class="text-muted mr-2">
              <del>{{ product.price | currency : currency.code }}</del>
            </span>
          </span>
          {{
            (isDiscounted()
              ? Math.round(
                  product.price * (1 - product.discount_rate / 100) * 100
                ) / 100
              : product.price
            ) | currency : currency.code
          }}
          <span class="vertical-separator" *ngIf="product?.price_global"></span>
          <span class="text-price mr-2" *ngIf="product?.price_global">{{
            (isDiscounted(product)
              ? Math.round(
                  product.price_global * (1 - product.discount_rate / 100) * 100
                ) / 100
              : product.price_global
            ) | currency : "USD" : "USD"
          }}</span>
        </p>
        <p
          *ngIf="product.user_access == 'plan'"
          class="text-left text-info font-weight-bold textFont"
          title="Este curso está incluido en tu plan"
        >
          {{ "Included" | translate }}
          <del> {{ product.price | currency : currency.code }} </del>
        </p>
      </div>

      <ng-template #free>
        <div class="col">
          <p class="text-left text-info font-weight-bold textFont">
            {{ "Free" | translate }}
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
