import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  private elementId: string;

  constructor() {
    this.elementId = "favicons-service-injected-node";
  }

  public set( href: string ) : void {
		this.setNode( href );
	}

  private addNode( href: string ) : void {
		let linkElement = document.createElement( "link" );
		linkElement.setAttribute( "id", this.elementId );
		linkElement.setAttribute( "rel", "icon" );
		// linkElement.setAttribute( "type", type );
		linkElement.setAttribute( "href", href );
		document.head.appendChild( linkElement );
  }

  // I remove any favicon nodes that are not controlled by this service.
	private removeExternalLinkElements() : void {
		let linkElements = document.querySelectorAll( "link[ rel ~= 'icon' i]" );

		for ( let linkElement of Array.from( linkElements ) ) {
			linkElement.parentNode.removeChild( linkElement );
		}
  }

  // I remove the favicon node from the document header.
	private removeNode() : void {
		let linkElement = document.head.querySelector( "#" + this.elementId );

		if ( linkElement ) {
			document.head.removeChild( linkElement );
		}
	}

  private setNode( href: string ) : void {
    this.removeExternalLinkElements();
		this.removeNode();
		this.addNode( href );
	}
}
