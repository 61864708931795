import {Injectable} from '@angular/core';
import {ApiService} from './Api.service';

@Injectable({
  providedIn: 'root'
})
export class SuscriptionService {
  constructor(private apiService: ApiService) {
  }
  
  async getPublicSuscriptionId(id) {
    try {
      const suscription = await this.apiService.get('public/suscription/'+id);
      return suscription;
    } catch (error) {
      return error;
    }
  }
 
}
