<div class="container">
  <div class="row justify-content-center my-5">
    <img class="col-auto img-logo" [src]="infoLanding?.logo?.medium" />
  </div>
  <div class="row justify-content-center">
    <div class="card card-lead col-11">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-auto">
            <img
              class="col-auto img-check"
              src="assets/images/landing/check.png"
            />
          </div>
          <div class="col-12 text-center">
            <h3 class="title my-5">
              {{ infoLanding?.thank_you_page_title }}
            </h3>
          </div>
          <div class="col-md-7 col-12 text-center">
            <p class="text-desc">
              {{ infoLanding?.thank_you_page_info }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center my-5">
    <div class="col-12">
      <ng-container
        *ngIf="infoLanding?.thank_you_page_video?.video_type == 'youtube'"
      >
        <iframe
          width="100%"
          height="auto"
          [src]="company.base_url + '/video-player/' + infoLanding.video.id | safe"
          frameborder="0"
          allow="autoplay;"
        >
          <img
            class="video-img"
            [src]="infoLanding?.thank_you_page_video?.thumbnail"
            alt="Miniatura del video"
          />
        </iframe>
      </ng-container>
      <ng-container
        *ngIf="infoLanding?.thank_you_page_video?.video_type == 'file'"
      >
        <div
          class="thumbContainer hover-shadow"
          style="margin-bottom: 0"
          (click)="openVideoModal(infoLanding?.thank_you_page_video?.id)"
          data-toggle="modal"
          [attr.data-target]="'#modal-video'"
        >
          <img
            alt="Video thumbnail"
            [src]="
              infoLanding?.thank_you_page_video?.thumbnail.replace(
                'small',
                'medium'
              )
            "
            onerror="this.src='assets/images/video_brok.jpg'"
            loading="lazy"
            decoding="async"
          />
          <div class="playContainer">
            <img
              alt="Play button"
              class="playButton"
              src="assets/images/lesson/play.svg"
              loading="lazy"
              decoding="async"
              height="45"
              width="45"
            />
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-auto my-5">
      <a
      *ngIf="infoLanding?.thank_you_page_btn_text != ''"
        [href]="infoLanding?.thank_you_page_btn_url"
        target="_blank"
        class="btn btn-primary col-auto my-4"
      >
        {{ infoLanding?.thank_you_page_btn_text }}
      </a>
    </div>
  </div>
</div>

<!-- Modals -->
<div
  class="modal fade"
  [id]="'modal-video'"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          #closeModal
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img
          alt="Video image"
          *ngIf="!videoLoaded"
          class="img-fluid"
          style="margin: auto"
          src="assets/images/loading.gif"
          loading="lazy"
          decoding="async"
        />
        <div class="playerWrap" *ngIf="infoLanding?.thank_you_page_video?.id">
          <iframe
            *ngIf="videoLoaded"
            [src]="
              company.base_url +
                '/video-player/' +
                infoLanding?.thank_you_page_video?.id | safe
            "
            style="width: 100%; border: none; aspect-ratio: 4/3"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Modals -->
