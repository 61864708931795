import { Component, OnInit } from "@angular/core";
import { CompanyModel } from "../../../Models/CompanyModel";
import { InitProvider } from "../../../init-provider";
import { phone } from "phone";
import ImageModel from "../../../Interfaces/ImageModel";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  company: CompanyModel;
  currentYear = new Date().getFullYear();
  constructor(initProvider: InitProvider, private router: Router) {
    this.company = initProvider.getSettings();
    console.log(this.company.footer);
  }

  ngOnInit(): void {}

  generateGoogleMapsUrl(address) {
    return `https://www.google.com/maps/search/?api=1&query=${address}`;
  }

  parsePhoneNumber(phoneNumber) {
    return phone(phoneNumber).phoneNumber;
  }

  validPhoneNumber(phoneNumber) {
    return phone(phoneNumber).isValid;
  }

  isGenericImage(image: ImageModel) {
    return (
      image["small"].includes("generic") ||
      image["medium"].includes("generic") ||
      image["large"].includes("generic")
    );
  }
  isOnSpecificRoute() {
    return (
      this.router.url.startsWith("/logged/ia-messages") ||
      this.router.url.startsWith("/logged/faqs")
    );
  }
}
