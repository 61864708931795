import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InitProvider} from '../../../init-provider';
import {AuthService} from '../../../Services/Auth/auth.service';

/*
 * Menu interface
 */
export interface Menu {
  state: any;
  name?: string;
  type?: string;
  fragment?: string;
  key?: string;
  icon?: string;
  children?: ChildrenItems[];
  id?: string;
}

export interface HeaderMenu {
  state: any;
  name: string;
  type: string;
  fragment?: undefined | string;
  key?: string;
  icon?: string;
  children?: ChildrenItems[];
  id?: string;
}

export interface AuthMenu {
  state: any;
  name: string;
  type: string;
  fragment?: string;
  key: string;
  icon?: string;
  children?: ChildrenItems[];
  id?: string;
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}


@Injectable()
export class MenuItems {
  HEADERMENUITEMS: Array<HeaderMenu> = [
    {
      state: 'top',
      name: 'Home',
      type: 'custom',
      fragment: 'home'
    },
    {
      state: ['blog', 'home'],
      name: 'Blogs',
      type: 'nested',
      key: 'blog',
    },
  ];

  AUTHITEMS = [
    {
      state: ['logged', 'home'],
      name: 'Home',
      type: 'nested'
    },

    {
      state: ['events', 'list'],
      name: 'Events',
      type: 'nested',
      key: 'events',
    },
    {
      state: ['events', 'presential'],
      name: 'Presential events',
      type: 'nested',
      key: 'presential_event'
    },
    {
      state: ['courses', 'aula'],
      name: 'Courses',
      type: 'nested',
      key: 'courses'
    },
    {
      state: ['programs', 'list'],
      name: 'Programs',
      type: 'nested',
      key: 'programs'
    },
    {
      state: ['products', 'list'],
      name: 'Products',
      type: 'nested',
      key: 'products'
    },
    {
      state: ['teachers', 'list'],
      name: 'Teachers',
      type: 'nested',
      key: 'teachers',
    },
    {
      state: ['contests', 'list'],
      name: 'Contests',
      type: 'nested',
      key: 'contests',
    },
    {
      state: ['blog', 'home'],
      name: 'Blog',
      type: 'nested',
      key: 'blog'
    },
    {
      state: ['logged', 'profile'],
      query: {section: 'plans'},
      name: 'Plans',
      type: 'mobile'
    },
    {
      state: ['logged', 'faqs'],
      name: 'Support',
      type: 'mobile'
    },
    {
      state: ['logged', 'profile'],
      name: 'Profile',
      type: 'mobile'
    },
  ];
  course_text = 'Course';
  lesson_text = 'Unit';
  course_text_s;
  lesson_text_s;
  private company: any;
  private currentUser: any;

  constructor(
    public translate: TranslateService,
    private initProvider: InitProvider,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.user;
    this.company = this.initProvider.getSettings();
   
    this.disableMenus();
    this.setTeachersMenu();
    this.changeMenuNames();

  }

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return this.HEADERMENUITEMS;
  }

  getAuthMenu(): Menu[] {

    return this.AUTHITEMS;
  }

  changeMenuNames(){
    const user_language =  this.currentUser ?  this.currentUser.language : this.company.language;
    const json = JSON.parse(this.company.faq_json);
    let digital_event_name="Digital events";
    let presential_event_name="Presential events";
    let event_name="Events";
    let courses_name="Courses";
    

    
    
    if (user_language === "en") {
      digital_event_name = json.texts?.menu_digital_events_en
      ? json.texts.menu_digital_events_en
      : "Digital events";
      presential_event_name = json.texts?.menu_presential_events_en
      ? json.texts.menu_presential_events_en
      : "Presential events";
      event_name = json.texts?.menu_events_en
      ? json.texts.menu_events_en
      : "Events";
      courses_name = json.texts?.menu_courses_en
      ? json.texts.menu_courses_en
      : "Courses";
    }
    
    if (user_language === "es") {
      digital_event_name = json.texts?.menu_digital_events_es
      ? json.texts.menu_digital_events_es
      : "Digital events";
      presential_event_name = json.texts?.menu_presential_events_es
      ? json.texts.menu_presential_events_es
      : "Presential events";
      event_name = json.texts?.menu_events_es
      ? json.texts.menu_events_es
      : "Events";
      courses_name = json.texts?.menu_courses_es
      ? json.texts.menu_courses_es
      : null;  //esto es null porq si no tiene quiero que tenga la otra opcion
    }



    if (this.company.has_presential_events) {
      
      
      
      this.AUTHITEMS = this.AUTHITEMS.map(item => {
        if (item.key === 'events') {
          return { ...item, name: digital_event_name };
        }
        if (item.key === 'presential_event') {
          return { ...item, name: presential_event_name };
        }
        return item;
      });


     
 
    }else{
      console.log('event_name',event_name)
      this.AUTHITEMS = this.AUTHITEMS.map(item => {
        if (item.key === 'events') {
        
          return { ...item, name: event_name };
        }
        if (item.key === 'courses') {
        
          return { ...item, name: courses_name };
        }
        
        return item;
      });
    }
    //voy a reemplazar las key name por el nombre que este en el json, en caso que este


  }
  setMenuCourseName(name) {
    const coursesMenu = this.AUTHITEMS.find(x => x.key === 'courses');
    if (coursesMenu) {
      coursesMenu.name = name;
    }
  }


  setMenuProgramName(name) {
    const programsMenu = this.AUTHITEMS.find(x => x.key === 'programs');
    if (programsMenu) {
      programsMenu.name = name;
    }
  }
  private setTeachersMenu() {
    const teachersMenu = this.AUTHITEMS.find(item => item.key === 'teachers');
    const language = this.currentUser ? this.currentUser.language : this.company.language;
    if (teachersMenu) {
      teachersMenu.name = language === 'en'
        ? (this.company.teachers_menu_en ? this.company.teachers_menu_en : 'Teachers')
        : (this.company.teachers_menu_es ? this.company.teachers_menu_es : 'Profesores');
    }
  }

  private disableMenus() {
    if (!this.company.has_courses) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'courses'), 1);
    }
    if (!this.company.has_events) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'events'), 1);
    }
    if (!this.company.has_teachers) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'teachers'), 1);
    }
    if (!this.company.has_contests) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'contests'), 1);
    }
    if (!this.company.has_programs) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'programs'), 1);
    }
    if (!this.company.has_products) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'products'), 1);
    }

    if (!this.company.has_presential_events) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'presential_event'), 1);
    }
    if (!this.company.has_blog) {


      this.HEADERMENUITEMS.splice(this.HEADERMENUITEMS.findIndex(item => item.key === 'blog'), 1);

      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'blog'), 1);
    }
  }

  public pushAuthMenu(menu: AuthMenu) {
    this.AUTHITEMS.push(menu);
    console.log(this.AUTHITEMS);
  }

  public pushHeaderMenu(menu: HeaderMenu) {
    this.HEADERMENUITEMS.push(menu);
  }
}
