import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BlockModel} from '../../Interfaces/BlockModel';
import {CompanyModel} from 'src/app/Models/CompanyModel';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-block-text',
  templateUrl: './block-text.component.html',
  styleUrls: ['./block-text.component.scss']
})
export class BlockTextComponent implements OnInit {
  @Input() block: BlockModel;
  @Input() company: CompanyModel;
  videoLoaded: boolean;
  subtitleText:any;
  private changeDetectorRef: ChangeDetectorRef;

  constructor(protected sanitizer: DomSanitizer,) {
  }

  ngOnInit(): void {
   
    if (this.block.subtitle?.substring(0, 3)=='<p>'){ 
      const restoDelTexto = this.block.subtitle?.substring(3);
      this.subtitleText=this.sanitizer.bypassSecurityTrustHtml('<p style="color:'+this.block.secondary_color+';">'+ restoDelTexto);
    }else{
      this.subtitleText=this.block.subtitle;
    }
  }
  hasTitleLink(){
    return this.block.title_url?true:false;
  }
  gotToLink(){
    if (this.block.title_url){
      window.location.href = this.block.title_url;
    }
  }
  initializeVideoPlayer(videoID) {
    this.videoLoaded = true;
 
    $('#modal-video-' + videoID).on('hide.bs.modal', () => {
      // player.unload();
      console.log('video false')
      this.videoLoaded = false;
    });
  }

  openVideoModal(videoID) {
    this.videoLoaded = true;
    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  hasBlockImage() {
    return this.block.block_image && !this.isGenericImage();
  }

  isGenericImage() {
    return this.block.block_image && this.block.block_image.large.includes('generic');
  }

}
