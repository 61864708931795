import {Component, Input,Output, OnInit,EventEmitter} from '@angular/core';
import {AuthService} from 'src/app/Services/Auth/auth.service';
import {ApiService} from '../../Services/Api.service';
import { Router} from '@angular/router';
import {InitProvider} from 'src/app/init-provider';

@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {
  @Input() ID: string;
  @Input() type: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  company=null;
  currentUser;
  errName = false;
  errCountry= false;
  errEmail = false;
  errEmailInvalid = false;
  errPhone = false;
  errPhoneInvalid = false;
  errNameSplit = false;
  errDni = false;
  saveLoading = false;
  countries:any;
  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private router: Router, 
    private initProvider: InitProvider
  ) {
    this.company = this.initProvider.getSettings();
  }

  ngOnInit() {
    this.currentUser = this.authService?.user;
    if (!this.currentUser?.invoice_country){
      if (this.currentUser)
      {
        this.currentUser.invoice_country=''
      }
    }
    
 
      this.apiService.get('states?items_per_page=300')
      .then(response => {
        //console.log(response.data);
        this.countries = response?.data;
  
      }).catch(error => {
      console.error(error);
    });
    

  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }


  async onSaveProfile() {
    console.log('saved this22!!!', this);
    this.saveLoading = true;
    this.errName = false;
    this.errCountry = false;
    this.errNameSplit = false;
    this.errEmail = false;
    this.errDni = false;
    this.errEmailInvalid = false;
    this.errPhone = false;
    this.errPhoneInvalid = false;
    if (!this.currentUser.invoice_name?.trim()) {
      console.log('no tiene nombre');
      this.errName = true;
    } else {
      const nameArray = this.currentUser.invoice_name.trim().split(' ');
      if (nameArray.length < 2) {
        this.errNameSplit = true;
      }
    }
    if (!this.currentUser.invoice_dni?.trim()) {
      this.errDni = true;
    }
    if (!this.currentUser.invoice_country?.trim()) {
      this.errCountry = true;
    }
    if (!this.currentUser.phone_number_invoice?.trim()) {
      this.errPhone = true;
    }

    if (!this.currentUser.invoice_email?.trim()) {
      this.errEmail = true;
    } else {
      if (!this.validateEmail(this.currentUser.invoice_email.trim())) {
        this.errEmailInvalid = true;
      }


    }

    if (this.errPhone || this.errCountry || this.errEmail || this.errDni || this.errName || this.errEmailInvalid || this.errNameSplit) {
      return;
    }
    const saved = await this.apiService.put('users/' + this.currentUser.id, {

      invoice_name: this.currentUser.invoice_name,
      invoice_dni: this.currentUser.invoice_dni,
      invoice_address: this.currentUser.invoice_address,
      invoice_giro: this.currentUser.invoice_giro,
      invoice_razon_social: this.currentUser.invoice_razon_social,
      invoice_email: this.currentUser.invoice_email,
      invoice_country: this.currentUser.invoice_country,
      phone_number_invoice: this.currentUser.phone_number_invoice,

    }).catch(err => err);

    if (saved.error) {
      if (saved.error.errors.email) {
        return alert(saved.error.errors.email[0]);
      } else if (saved.error.errors.first_name) {
        return alert(saved.error.errors.first_name[1]);
      } else if (saved.error.errors.last_name) {
        return alert(saved.error.errors.last_name[1]);
      }
    }
    console.log('aaa saved!', saved);
    console.log(saved);
    this.authService.saveUserData();
    this.saveLoading = false;
    $('#modal-timezone').modal('hide');
    if (!this.type){
       this.closeModal.emit('close');
    }
    if (this.type === 'Course') {
      console.log('Course');
      // this.router.navigate(['/courses/purchase', this.ID]);
      this.router.navigate(['/purchases', 'course', this.ID]);
    }

    if (this.type === 'Plan') {
      console.log('Plan');
      this.router.navigate(['/purchases', 'plan', this.ID]);
      //this.router.navigate(['/logged/profile/payment'], {queryParams: {plan: this.ID}});
    }

    if (this.type === 'Event') {
      console.log('Event');
      this.router.navigate(['/purchases', 'event', this.ID]);
      // this.router.navigate(['/events/register', this.ID]);
    }
  }
}
