import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {BlockModel} from '../../Interfaces/BlockModel';
import {CompanyModel} from '../../Models/CompanyModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit, AfterViewInit  {
  @Input() block: BlockModel;
  @Input() company: CompanyModel;
  @Input() logged: boolean;
  @Input() fromHome: boolean = false;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  sluggify(string) {
    if (!string || string === '') {
      return '';
    }
    return string.toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }
  
  ngAfterViewInit() {
   
  }
  getBlockStyles() {
    const styles = {
      padding: '2rem 0',
    };
    if (this.block.type === 'slider') {
      styles['padding'] = '0';
    }
    if (!this.block.background_image || this.isGenericImage()) {
      styles['background-color'] = this.block.background_color;
      return styles;
    }

    styles['background-image'] = 'url(' + this.block.background_image.large + ')';
    styles['background-color'] = this.block.background_color;
    return styles;
  }

  isGenericImage() {
    return this.block.background_image && this.block.background_image.large.includes('generic');
  }

    cleanString(input: string): string {
      if (!input) return '';

      // Mapa de caracteres acentuados que queremos eliminar
      const accentsRegex = /[áéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ]/g;

      // Eliminar las letras acentuadas
      const withoutAccents = input.replace(accentsRegex, '');

      // Reemplazar espacios con guiones
      const transformedString = withoutAccents.replace(/\s+/g, '-');
      return transformedString;
    }
}
