<header class="cover">
  <div class="cover--imageBackground">
    <img
      *ngIf="isPhoneView()"
      [src]="phoneImageUrl.medium"
      loading="eager"
      decoding="async"
      [srcset]="phoneImageUrl | srcset"
    />
    <img
      *ngIf="isDesktopView()"
      [src]="desktopImageUrl.medium"
      loading="eager"
      decoding="async"
      [srcset]="desktopImageUrl | srcset"
    />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-5 mb-3 mt-5">
        <div class="row">
          <div class="col-12 p-4 position-relative">
            <div class="transparent-bg d-none d-md-block"></div>
            <div class="row">
              <div class="col-12 col-md-10">
                <h3 class="cover--title text-primary">
                  {{ company.login_text_title }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="cover--description text-secondary">
                  {{ company.login_text_description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 offset-lg-3">
        <div class="session-content text-center card">
          <div class="mb-2">
            <h5 class="text-capitalize font-weight-bold mb-4">{{ "Login" }}</h5>
          </div>
          <div>
            <form [formGroup]="form" class="mb-2">
              <div class="form-group text-left icon-form">
                <input
                  name="name-input"
                  class="form-control custom-input"
                  type="email"
                  value=""
                  placeholder="Email"
                  formControlName="email"
                />
                <img
                  src="assets/images/mootiva/mail.svg"
                  loading="lazy"
                  decoding="async"
                />

                <small
                  class="text-danger"
                  *ngIf="
                    form.controls['email'].hasError('required') &&
                    form.controls['email'].touched
                  "
                >
                  {{ "You must enter an email" | translate }}
                </small>
                <small
                  class="text-danger"
                  *ngIf="
                    form.controls['email'].errors &&
                    !form.controls['email'].hasError('required') &&
                    form.controls['email'].touched
                  "
                >
                  {{ "You must enter an valid email" | translate }}
                </small>
              </div>
              <div class="form-group text-left icon-form">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  name="name-input"
                  class="form-control custom-input"
                  value=""
                  [placeholder]="'Password' | translate"
                  formControlName="password"
                />
                <span
                  (click)="showHidePassword()"
                  style="cursor: pointer"
                  class="fa fa-fw field-icon toggle-password"
                  [ngClass]="showPassword == true ? ' fa-eye' : ' fa-eye-slash'"
                ></span>
                <img
                  src="assets/images/mootiva/pass.svg"
                  loading="lazy"
                  decoding="async"
                />
                <small
                  class="text-danger"
                  *ngIf="
                    form.controls['password'].hasError('required') &&
                    form.controls['password'].touched
                  "
                >
                  {{ "You must enter a password" | translate }}
                </small>
              </div>
              <small class="text-danger" *ngIf="messageError">
                {{ messageError | translate }}
              </small>
              <div class="form-group mt-5">
                <button
                  class="btn btn-info"
                  [class.disabled]="loading"
                  href="javascript:void(0);"
                  (click)="logIn()"
                >
                  {{ "Enter" | translate }}
                  <i
                    class="fa fa-circle-notch fa-spin ml-1"
                    *ngIf="loading"
                  ></i>
                </button>
              </div>
            </form>

            <div class="row" *ngIf="company.allow_registration">
              <div class="col-12">
                <p class="mb-0">
                  <span class="text-muted">{{
                    "You do not have an account yet?" | translate
                  }}</span>

                  <!-- <a *ngIf="view_plans_logout" [state]="{ ignoreLoadingBar: true }"  target="_self" class="font-weight-bold ml-2" [routerLink]="['/home']" fragment="pricing"> {{ "Sign Up" | translate}} </a> -->
                  <a class="font-weight-bold ml-2" [routerLink]="['/register']">
                    {{ "Sign Up" | translate }}
                  </a>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <a class="font-weight-bold" [routerLink]="['/forgot']">{{
                  "I forgot my password" | translate
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
