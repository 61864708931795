<div class="row mb-4 pb-2 border-bottom">
    <div class="col-md-12 d-flex justify-content-between flex-center"  [class.collapsed]="collapsed" (click)="toggleCollapse()">
        <p class="title mt-2"> {{ title }} </p>
        <button class="btn btnRound btnToggler d-flex justify-content-center align-items-center">
          <img src="assets/images/mootiva/plus_icon.svg" width="20" height="20" loading="lazy" decoding="async">
        </button>
    </div>
</div>
<div class="row mb-4 fadeIn" *ngIf="collapsed">
    <div class="col-md-12 description">
        <p [innerHTML]="content"></p>
    </div>
</div>
