import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subscription, interval } from "rxjs";
import { InitProvider } from "src/app/init-provider";
declare var $;
@Component({
  selector: "app-high-ticket",
  templateUrl: "./high-ticket.component.html",
  styleUrls: ["./high-ticket.component.scss"],
})
export class HighTicketComponent implements OnInit {
  @Input() infoLanding: any = {};

  targetDate: Date = new Date("");
  timeLeft: string = "";
  programToSell: any = [];
  aditionalProducts: any = [];
  private subscription!: Subscription;

  company = null;
  constructor(
    public sanitizer: DomSanitizer,
    public initProvider: InitProvider,
  ) {}

  ngOnInit(): void {
    this.updateCountdown();
    this.subscription = interval(1000).subscribe(() => {
      this.updateCountdown();
    });

    const company = this.initProvider.getSettings();
    this.company = company;
  }
  safeUrl: SafeResourceUrl | undefined;
  ngOnChanges(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.targetDate = new Date(this.infoLanding?.data_json?.end_date);
    if (this.targetDate) {
      this.updateCountdown();
      this.subscription = interval(1000).subscribe(() => {
        this.updateCountdown();
      });
    }

    if (this.infoLanding?.video?.youtube_link) {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.formatYoutubeLink(
          this.infoLanding?.video?.youtube_link
            ? this.infoLanding?.video?.youtube_link
            : ""
        )
      );
    }

    this.programToSell = this.infoLanding.program_to_sell_data.subjects.flatMap(
      (subject) => subject.objects
    );

    if (this.infoLanding?.product_aditional_data) {
      this.aditionalProducts.push(this.infoLanding?.product_aditional_data);
    }
    if (this.infoLanding?.program_aditionall_data) {
      this.aditionalProducts.push(this.infoLanding?.program_aditionall_data);
    }
    if (this.infoLanding?.course_aditional_data) {
      this.aditionalProducts.push(this.infoLanding?.course_aditional_data);
    }
    if (this.infoLanding?.event_aditional_data) {
      this.aditionalProducts.push(this.infoLanding?.event_aditional_data);
    }
    this.totalPrice = this.infoLanding.data_json.program_sale_price;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  private updateCountdown(): void {
    const now = new Date();
    const difference = this.targetDate.getTime() - now.getTime();
    if (difference <= 0) {
      this.timeLeft = "¡Oferta!";
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      const parts = [];
      if (days > 0) parts.push(`${days}d`);
      if (hours > 0) parts.push(`${hours}h`);
      if (minutes > 0) parts.push(`${minutes}m`);
      if (seconds > 0) parts.push(`${seconds}s`);

      this.timeLeft = parts.join(" ");
    }
  }
  private formatYoutubeLink(link: string): string {
    const videoId = link?.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  public selectedProducts: any[] = [];
  isChecked = false;
  totalPrice: number;
  toggleSelection(item: any): void {
    this.isChecked = !this.isChecked;
    this.updateTotalPrice();
  }

  updateTotalPrice(): void {
    const programSalePrice =
      Number(this.infoLanding.data_json.program_sale_price) || 0;
    const priceTodayAdditionalSale =
      Number(this.infoLanding.data_json.price_today_additional_sale) || 0;

    if (this.isChecked) {
      this.totalPrice = programSalePrice + priceTodayAdditionalSale;
    } else {
      this.totalPrice = programSalePrice;
    }
  }

  isSelected(): boolean {
    return this.isChecked;
  }
  videoLoaded = false;

  initializeVideoPlayer(videoID) {
    $("#modalpreview-" + videoID).on("hide.bs.modal", () => {
      // player.unload();

      this.videoLoaded = false;
    });
  }
  openVideoModal(videoID) {
    this.videoLoaded = true;
    //open_course_video_preview

    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  imageLoaded = false;
  onImageLoad() {
    this.imageLoaded = true;
  }

  cleanHtmlString(input: string): string {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
  }
}
