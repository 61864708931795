import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {InitProvider} from 'src/app/init-provider';
import * as moment from 'moment';

@Component({
  selector: "product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent implements OnInit {

  @Input() product: any;
  currency = null;
  Math = Math;
  constructor(
    private router: Router,  
    private initProvider: InitProvider,
  ) { }

  ngOnInit(): void {
    const company = this.initProvider.getSettings();
    this.currency = company.currency;
  }

  goToProfile(id: string) {
    this.router.navigate(["/products", "profile", id]);
  }
  isDiscounted() {
    let discount= this.product.discount_has && moment(this.product.end_date) > moment();
 
    return discount
  }
  
     
}
