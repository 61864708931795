import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import {InitProvider} from 'src/app/init-provider';

@Component({
	selector: 'app-banner-plan',
	templateUrl: './BannerPlan.component.html',
	styleUrls: ['./BannerPlan.component.scss']
})
export class BannerPlan implements OnInit {

	public text = null
	public user: any = null;
	public company: any = null;
	public BK_color=null;
	constructor(public authService: AuthService,    private initProvider: InitProvider,) {
		this.company = this.initProvider.getSettings();
	}

	ngOnInit() {
		this.user = this.authService.user;
		if (this.user){
			this.text = this.user.plan.info_plan_banner
			//console.log(this.company)
			let colorRGB=this.hexToRgb(this.company.color_1)
			this.BK_color='rgba('+colorRGB+',0.05)'
		}
	
	

	}
	hexToRgb(hex: string): string {
	//	console.log('color',hex)
		hex = hex.replace(/^#/, '');
		const bigint = parseInt(hex, 16);
		const r = (bigint >> 16) & 255;
		const g = (bigint >> 8) & 255;
		const b = bigint & 255;
		console.log(`${r}, ${g}, ${b}`)
		return `${r}, ${g}, ${b}`;
	}

}
