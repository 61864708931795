<div class="container fadeIn" *ngIf="productList && productList.length > 0">
  <div *ngIf="block?.show_titles" class="row">
    <div class="col-12">
      <h2 class="titleTextFont font-weight-normal text-center my-3" style="font-size: 2.4rem;"
          [ngStyle]="{'color':block.titles_color?block.titles_color:''}"
          [ngClass]="{'cursor-pointer': hasTitleLink()}"
          (click)="gotToLink()"
          >

        {{ block.title }}
      </h2>
    </div>
    <div *ngIf="block.subtitle" class="col-12">
      <h2 class="font-weight-normal fadeIn text-center mb-3 subtitle-block" style="font-size: 1.5rem;"

          [ngStyle]="{'color':block.secondary_color?(block.secondary_color):''}"
          [innerHTML]="subtitleText">
 
      </h2>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-4 animate__animated animate__fadeIn"
      *ngFor="let product of productList"
    >
      <product-card [product]="product"></product-card>
    </div>
  </div>
</div>