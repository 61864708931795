<div
  class="container-fluid"
  [ngStyle]="{
    'background-image': !infoLanding?.background_image_01?.large.includes('generic.png') ? 'url(' + infoLanding?.background_image_01?.large + ')' : '',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
    'background-size': 'cover',
    height: '100%'
  }"
>
  <div class="container py-4">
    <div class="row justify-content-center my-5">
      <img class="col-auto img-logo" [src]="infoLanding?.logo?.medium" />
    </div>
    <div class="row text-center justify-content-center my-4">
      <div class="col-12">
        <p class="text-inner" [innerHTML]="sanitizer.bypassSecurityTrustHtml(infoLanding?.title)"></p>
      </div>
    </div>
  </div>
</div>

<div
  class="container-fluid"
  [ngStyle]="{
    'background-image': !infoLanding?.background_image_02?.large.includes('generic.png') ? 'url(' + infoLanding?.background_image_02?.large + ')' : '',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
    'background-size': 'cover',
    height: '100%'
  }"
>
  <div class="container pt-md-5 pb-5">
    <div class="row justify-content-center">
      <div class="col-12">
        <ng-container *ngIf="infoLanding?.video?.video_type == 'youtube'">
          <iframe
            width="100%"
            height="auto"
            [src]="company.base_url + '/video-player/' + infoLanding.video.id | safe"
            frameborder="0"
            allow="autoplay;"
          >
            <img
              class="video-img"
              [src]="infoLanding?.video?.thumbnail"
              alt="Miniatura del video"
            />
          </iframe>
        </ng-container>
        <ng-container *ngIf="infoLanding?.video?.video_type == 'file'">
          <div
            class="thumbContainer hover-shadow"
            style="margin-bottom: 0"
            (click)="openVideoModal(infoLanding?.video?.id)"
            data-toggle="modal"
            [attr.data-target]="'#modal-video'"
          >
            <img
              alt="Video thumbnail"
              [src]="infoLanding?.video?.thumbnail.replace('small', 'medium')"
              onerror="this.src='assets/images/video_brok.jpg'"
              loading="lazy"
              decoding="async"
            />
            <div class="playContainer">
              <img
                alt="Play button"
                class="playButton"
                src="assets/images/lesson/play.svg"
                loading="lazy"
                decoding="async"
                height="45"
                width="45"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row justify-content-center mt-md-5 mb-5">
      <div class="card card-lead col-11">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-12 d-flex flex-column">
              <h3
                class="title-card-lead"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(infoLanding?.data_json?.lead_magnet_title)"
              ></h3>
              <p
                class="text-lead"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(infoLanding?.data_json?.lead_magnet_description)"
              ></p>
            </div>
            <div class="col-md-6 col-12 d-flex flex-column">
              <h4
                class="mb-3 subtitle-card-lead mb-4"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(infoLanding?.data_json?.lead_magnet_instructions)"
              ></h4>
              <label for="inputlead" class="form-label">{{
                "Email" | translate
              }}</label>
              <input
                (input)="checkEmailValidity()"
                [(ngModel)]="email"
                name="email"
                required
                type="email"
                [disabled]="loadingSend"
                class="form-control input-landing px-2"
                id="inputlead"
                aria-describedby="emailHelp"
                [placeholder]="'tuemail@ejemplo.com' | translate"
              />


              <label for="inputlead" class="form-label mt-2">{{
                "Número de whatsapp" | translate
              }}</label>
              <input
                (input)="checkWpValidity()"
                [(ngModel)]="wpNumber"
                name="wpnumer"
                required
                type="number"
                [disabled]="loadingSend"
                class="form-control input-landing px-2"
                id="inputlead"
                aria-describedby="wpHelp"
                [placeholder]="'ej: 543425085044' | translate"
              />



              <button
                (click)="sendEmail()"
                [disabled]="!isValidEmail ||!isValidNumber || loadingSend"
                class="btn btn-primary col-auto my-4"
              >
                {{ "Enviar" | translate }}
                <i
                  *ngIf="loadingSend"
                  class="fas fa-circle-notch fa-spin fa-1x"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modals -->
<div
  class="modal fade"
  [id]="'modal-video'"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          #closeModal
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img
          alt="Video image"
          *ngIf="!videoLoaded"
          class="img-fluid"
          style="margin: auto"
          src="assets/images/loading.gif"
          loading="lazy"
          decoding="async"
        />
        <div class="playerWrap" *ngIf="infoLanding?.video?.id">
          <iframe
            *ngIf="videoLoaded"
            [src]="
              company.base_url + '/video-player/' + infoLanding?.video?.id
                | safe
            "
            style="width: 100%; border: none; aspect-ratio: 4/3"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Modals -->
