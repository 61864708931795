import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'srcset'
})
export class SrcsetPipe implements PipeTransform {

  transform(value: {
    large: string;
    medium: string;
    small: string;
  }, ...args: unknown[]): unknown {
    return [
      value.large + ' 2000w',
      value.medium + ' 600w',
      value.small + ' 150w',
    ].toString();
  }

}
