import {Component, Input, OnInit} from '@angular/core';
import {InitProvider} from '../../../init-provider';

@Component({
  selector: 'app-theme-light-logo',
  templateUrl: './ThemeLightLogo.component.html',
  styleUrls: ['./ThemeLightLogo.component.scss']
})
export class ThemeLightLogoComponent implements OnInit {

  @Input() logoClass: any;

  public logo;

  constructor(initProvider: InitProvider) {
    this.logo = initProvider.getSettings().logo;
  }

  ngOnInit() {
  }
}
