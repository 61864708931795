<div
  class="container fadeIn"
  *ngIf="programList && programList.length > 0 && company.has_programs"
>
  <div *ngIf="block.show_titles" class="row">
    <div class="col-12">
      <h2
        class="titleTextFont font-weight-normal text-center"
        style="font-size: 2.4rem"
        [ngStyle]="{ color: block.titles_color ? block.titles_color : '' }"
        [ngClass]="{ 'cursor-pointer': hasTitleLink() }"
        (click)="gotToLink()"
      >
        {{ block.title }}
      </h2>
    </div>
    <div *ngIf="block.subtitle" class="col-12">
      <h2
        class="font-weight-normal fadeIn text-center mb-3 subtitle-block"
        style="font-size: 1.5rem"
        [ngStyle]="{
          color: block.secondary_color ? block.secondary_color : ''
        }"
        [innerHtml]="subtitleText"
      ></h2>
    </div>
  </div>
  <div class="row">
    <div
      class="courseItem col-md-4 col-12 pointer"
      *ngFor="let program of programList"
      [routerLink]="programLink(program)"
    >
      <div class="itemImage hover-shadow">
        <picture>
          <source
            media="(max-width: 799px)"
            srcset="{{ program?.image | srcset}}"
          />
          <img
            [src]="program.image.small"
            [srcset]="program.image | srcset"
            onerror="this.src = 'assets/images/mootiva/logo-dark.png';"
            decoding="async"
            loading="lazy"
            alt=""
          />
        </picture>
      </div>
      <div class="itemDetails">
        <p
          class="itemTitle hover-text-shadow textFont"
          [innerHTML]="program.name"
          [ngStyle]="{ color: block.link_color ? block.link_color : '' }"
        ></p>
        <div class="row justify-content-between" *ngIf="program.price && !program.purchased">
          <div class="col-auto">
            <p *ngIf="program.price" class="text-info font-weight-bold">
              {{
                (isDiscounted(program)
                  ? Math.round(
                      program.price * (1 - program.discount_rate / 100) * 100
                    ) / 100
                  : program.price
                ) | currency : currency.code
              }}
              <span
                class="vertical-separator"
                *ngIf="program?.price_global"
              ></span>
              <span class="text-price mr-2" *ngIf="program?.price_global">{{
                (isDiscounted(program)
                  ? Math.round(
                      program?.price_global *
                        (1 - program?.discount_rate / 100) *
                        100
                    ) / 100
                  : program?.price_global
                ) | currency : "USD" : "USD"
              }}</span>
            </p>
          </div>
          <div class="col-auto" *ngIf="program.user_access == 'plan'">
            <p
              class="text-info font-weight-bold textFont"
              title="Este curso está incluido en tu plan"
            >
              {{ "Included" | translate }}
            </p>
          </div>
        </div>
        <div
          class="row justify-content-between"
          *ngIf="
            program?.is_just_for_suscription && program.user_access == '';
            else price
          "
        >
          <div class="col-auto">
            <p class="text-right text-info font-weight-bold textFont mb-0 accessible-sub">
              {{ "Accessible only with Subscription" | translate }}
            </p>
          </div>
          <div class="col-auto">
            <p
              class="text-right font-weight-bold text-info pointer"
              (click)="goPlans()"
            >
              {{ "View Plans" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="block.button_text" class="col-md-10 offset-md-1 text-center">
    <button
      class="btn btn-info mt-0 mb-5"
      tabindex="0"
      [routerLink]="['/block', block.id]"
    >
      {{ block.button_text }}
    </button>
  </div>
</div>
