import {EventEmitter, HostListener, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PlayerService {

  events: EventEmitter<any> = new EventEmitter();

  constructor() {
    console.log('paso por el servicio');
    window.addEventListener('message', (event) => {
   
      if (event?.data?.entity === 'video') {
        this.events.emit(event);
      }
      // if (event.origin !== 'http://example.org:8080') {
      //   return;
      // }
    }, false);
  }

}
