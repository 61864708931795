import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DropletService} from '../../Services/Droplet.service';
import {InitProvider} from '../../init-provider';
import {CoursesService} from 'src/app/Services/Courses.service';
import {BlocksService} from 'src/app/Services/blocks.service';
import {EventsService} from 'src/app/Services/events.service';
import * as moment from 'moment-timezone';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import * as Player from '@vimeo/player/dist/player.js';

@Component({
  selector: 'app-block-profile',
  templateUrl: './Block.component.html',
  styleUrls: ['./Block.component.scss']
})
export class BlockComponent implements OnInit {
  gridOverlay: any;
  serviceItems: any;
  testimonialData: any;
  counterContent: any;
  pricingContent: any;
  company: any;
  courses = [];
  events = [];
  blocks = null
  Math = Math;
  whatsapp_number = '';
  course_text = 'Course';
  lesson_text = 'Unit';
  course_text_s;
  lesson_text_s;
  show_lesson_text;
  view_plans_logout = false;
  hideFreePlanInHome = false;
  slider = [];
  video = null;
  videoLoaded = false;
  videoId = null;
  id=null;

  constructor(
    public service: DropletService,
    public initProvider: InitProvider,
    public coursesService: CoursesService,
    public blocksService: BlocksService,    
    public eventsService: EventsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
   
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.company = initProvider.getSettings();
    this.whatsapp_number = this.company.whatsapp_number;
  }

  ngOnInit() {
 
   
    const json = JSON.parse(this.company.faq_json);
    if (json && json.hide_free_plan_in_home) {
      this.hideFreePlanInHome = true;
    }
    let company_language = this.company.language;
    this.show_lesson_text = json.configuration ? json.configuration.show_lessons_in_courses : 'true';
    //console.log(json.texts.course);


    if ( json.texts){
      if (company_language == 'en') {
        this.course_text = json.texts.course_en ? json.texts.course_en : 'course';
        this.lesson_text = json.texts.lesson_en ? json.texts.lesson_en : 'unit';
        this.course_text_s = json.texts.courses_en ? json.texts.courses_en : 'courses';
        this.lesson_text_s = json.texts.lessons_en ? json.texts.lessons_en : 'unit';
      }
      if (company_language == 'es') {
        this.course_text = json.texts.course_es ? json.texts.course_es : 'Curso';
        this.lesson_text = json.texts.lesson_es ? json.texts.lesson_es : 'Unidad';
        this.course_text_s = json.texts.courses_es ? json.texts.courses_es : 'Cursos';
        this.lesson_text_s = json.texts.lessons_es ? json.texts.lessons_es : 'Unidades';

      }
      if (company_language == 'it') {
        this.course_text = json.texts.course_it ? json.texts.course_it : 'Course';
        this.lesson_text = json.texts.lesson_it ? json.texts.lesson_it : 'Unit';
        this.course_text_s = json.texts.courses_it ? json.texts.courses_it : 'Courses';
        this.lesson_text_s = json.texts.lessons_it ? json.texts.lessons_it : 'Unit';

      }
    }

    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      this.getPublicBlocks(this.id);
 
    });

  



    

  }


  getPublicBlocks(id) {
    this.blocksService.getBlocksById(id).then((blocks) => {
      this.blocks = [blocks.data];
      console.log(this.blocks)
    }).catch((error) => {
      console.log(error);
    });
  }





  


  isDiscounted(course) {
    return course.discount_has && moment(course.discount_until) > moment();
  }

  


}
