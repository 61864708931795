import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './Api.service';
import {CookieService} from './cookie.service';

export const TRACKING_WEB_VISIT_COOKIE = 'Mootiva_web_visit';
export const TRACKING_WEB_VISIT_EVENT = 'web_visit';
export const TRACKING_CHECKOUT_VISIT_COOKIE = 'Mootiva_checkout_visit';
export const TRACKING_CHECKOUT_VISIT_EVENT = 'checkout_visit';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
  ) {
  }
  public trackEvent(eventName: string, data = {}): Promise<any> {
    return this.apiService.post('tracking', {track: eventName, value: '1'});
  }

  public trackData(data = {}): Promise<any> {
    return this.apiService.post('trackdata', data);
  }

  handleTracking(cookieName: string, eventName: string, expirationDays: number = 7): void {
      const hasVisitedCheckout = this.cookieService.get(cookieName);

      if (!hasVisitedCheckout) {
      this.trackEvent(eventName).then();

      // Create the cookie with the current date and expiration in 7 days
      const now = new Date();
      const expiration = new Date(now.getTime() + expirationDays * 24 * 60 * 60 * 1000);
      this.cookieService.set(cookieName, now.toISOString(), expiration, '/', window.location.hostname);
    } else {
      // Update the cookie with a new expiration date
      const now = new Date();
      const expiration = new Date(now.getTime() + expirationDays * 24 * 60 * 60 * 1000);
      this.cookieService.set(cookieName, now.toISOString(), expiration, '/', window.location.hostname);
    }
  }

}
