import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LandingService } from "src/app/Services/landing.service";
import { InitProvider } from "src/app/init-provider";

@Component({
  selector: "app-lead-magnet",
  templateUrl: "./lead-magnet.component.html",
  styleUrls: ["./lead-magnet.component.scss"],
})
export class LeadMagnetComponent implements OnInit, AfterViewInit {
  @Input() infoLanding: any = {};
  public videoLoaded = false;
  public loadingSend = false;
  public email: string = "";
  public wpNumber: string = "";
 
  isValidNumber: boolean = false;
  isValidEmail: boolean = false;
  company: any;
  constructor(
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    public initProvider: InitProvider,
    private ladingService: LandingService,
    private translate: TranslateService,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.company = initProvider.getSettings();
  }

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }
  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return "";
    }
    results = url.match("[\\?&]v=([^&#]*)");
    video = results === null ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/" + video
    );
  }

  openVideoModal(videoID) {
    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  initializeVideoPlayer(videoID) {
    this.videoLoaded = true;
    $("#modal-video").on("hide.bs.modal", () => {
      this.videoLoaded = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  public sendEmail() {
    this.loadingSend = true;
    this.ladingService
      .sendEmail(this.infoLanding.id, this.email, this.wpNumber)
      .then((infoLanding) => {
        this.loadingSend = false;
        this.toaster.success(this.translate.instant("E-mail sent"));
        this.router.navigate(["/landing/thank-you"], {});
      })
      .catch((error) => {
        this.toaster.error(this.translate.instant("Error sending email"));
        console.log(error);
        this.loadingSend = false;
      });
  }
  public checkEmailValidity(): void {
    console.log('test',this.email)
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    ;
    if (!emailRegex.test(this.email)) {
      this.isValidEmail = false;
    } else {
      this.isValidEmail = true;
    }
  }
  public checkWpValidity(): void {
    const regex = /^\d{8,15}$/; // Acepta números de 8 a 15 dígitos
    this.isValidNumber = regex.test(this.wpNumber);
  }
  
  public checkEmailValidity2(): void {
    
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
;


    
    if (!emailRegex.test(this.email)) {
      this.isValidEmail = true;
    } else {
      this.isValidEmail = false;
    }
  }
}
