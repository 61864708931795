import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SessionRoutes} from './Session.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SignUpFormComponent} from './SignUpForm/SignUpForm.component';
import {LoginComponent} from './LogIn/LogIn.component';
import {SignupComponent} from './SignUp/SignUp.component';
import {Signup2Component} from './SignUp2/SignUp2.component';
import {ForgotPasswordComponent} from './ForgotPassword/ForgotPassword.component';

import {MaintenanceComponent} from './Maintenance/Maintenance.component';
import {ComingsoonComponent} from './ComingSoon/ComingSoon.component';
import {PageNotFoundComponent} from './PageNotFound/PageNotFound.component';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../Pipes/Pipes.module';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    Signup2Component,
    MaintenanceComponent,
    ComingsoonComponent,
    PageNotFoundComponent,
    SignUpFormComponent,
    ForgotPasswordComponent


  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(SessionRoutes),
    TranslateModule,
    PipesModule,
  ],
  exports: [
    LoginComponent,
    SignupComponent,
    Signup2Component,
    MaintenanceComponent,
    ComingsoonComponent,
    PageNotFoundComponent,
    SignUpFormComponent,
    ForgotPasswordComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SessionModule {
}
