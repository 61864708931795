import {Injectable} from '@angular/core';
import {ApiService} from './Api.service';
import {AuthService} from 'src/app/Services/Auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BlocksService {


  publicUrl = 'public/blocks';
  privateUrl = 'login/blocks';
  pageUrl = 'getpage';
  pageUrllogin= 'getpagelogin';
  useURL = '';
  baseDataUrl = 'data';

  currentCourse: any;
  courses: any;

  constructor(private apiService: ApiService,
              public authService: AuthService) {

    let currentUser = this.authService.user;
    if (currentUser) {
      this.useURL = this.privateUrl;
    } else {
      this.useURL = this.publicUrl;

    }
  }
  async getPage(slug) {
    try {
      let page=null
      if (this.authService.user){
         page = await this.apiService.get(this.pageUrllogin +'/' + slug);    
      }else{
         page = await this.apiService.get(this.pageUrl +'/' + slug);    
      }
      return page;
    } catch (error) {
      return error;
    }
  }
  async getBlocks(id=null) {
    try {
      let url=this.useURL
      if (id){
        url=url+'?page_id='+id
      }
      const courses = await this.apiService.get(url);
      this.courses = courses;
      return courses;
    } catch (error) {
      return error;
    }
  }

  async getBlocksById(id) {
    try {
      const courses = await this.apiService.get(this.useURL + '/' + id);
      this.courses = courses;
      return courses;
    } catch (error) {
      return error;
    }
  }


}
