import { Component, Input, OnInit } from "@angular/core";
declare var $;
@Component({
  selector: "app-description-plan",
  templateUrl: "./description-plan.component.html",
  styleUrls: ["./description-plan.component.scss"],
})
export class DescriptionPlanComponent implements OnInit {
  // @Input() closeModal: () => void;
  @Input() item: any = {};

  constructor() {}

  ngOnInit(): void {}
  init(){
    
  }
  public closeModal() {
    $("#modal-description").modal("hide");
  }
}
