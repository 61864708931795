<ng-container *ngIf="typePlans == 'list'">
  <div class="row" *ngIf="showTitles">
    <div class="col-12 col-sm-6 offset-sm-3 mb-5">
      <h2
        [style.color]="company.home_font_color"
        class="titleTextFont pageTitle text-center"
      >
        {{ "Pricing" | translate }}
      </h2>
    </div>
  </div>
  <div *ngFor="let plan of plans">
    <div
      [class.selected]="plan.selected"
      *ngIf="showTrial === 'show' || plan.price > 0"
      class="row planContainer mb-3"
    >
      <div class="col-md-4 col-lg-4 itemBox">
        <img
          *ngIf="checkImagePath(plan?.image?.small)"
          class="img-hero"
          [src]="plan.image.small"
          loading="lazy"
          decoding="async"
          [srcset]="plan.image | srcset"
          [alt]="plan.name"
        />
        <div *ngIf="plan.selected" class="title-img">
          {{ "Your current plan" | translate }}
        </div>

        <!--<div class="discountBox mt-4">{{ plan.discount_description }}</div>-->
        <!-- <div *ngIf="plan.discount" class="discountBox "> <i class="fa fa-star"></i> SUPER DESCONTO!-->
        <!-- </div>-->
        <!-- <div *ngIf="plan.discount_description!=''" class="planTitle">{{ plan.name }}</div>-->
      </div>

      <div class="col-md-4 col-lg-6 overflow-auto plan-description">
        <div class="row titleDescription">
          <div *ngIf="plan.discount_description != ''" class="planTitle">
            {{ plan.name }}
          </div>
        </div>
        <div class="row mt-3">
          <span class="description-include mb-3" *ngIf="!plan.access_info">
            <strong>{{ "Include" | translate }}:</strong>
            {{
              plan.price == 0
                ? gender == "m"
                  ? ("Allow all free courses"
                    | translate
                      : { courses: (course_text_s.toLowerCase() | translate) })
                  : ("Allow all free courses:f"
                    | translate
                      : { courses: (course_text_s.toLowerCase() | translate) })
                : (plan.access.name | translate)
            }}</span
          >
          <span class="description-include mb-3" *ngIf="plan.access_info">
            <!-- <strong>{{ "Include" | translate }} :</strong> -->
            {{ plan.access_info }}
          </span>
          <ng-container *ngIf="!plan.access_info && plan.access.key != 'AACE'">
            <span
              class="description-include mr-1"
              *ngFor="
                let categ of plan.access_categories_all;
                let primero = first;
                let ultimo = last
              "
            >
              {{ primero ? " : " : " " }}{{ categ.name
              }}{{ ultimo ? " " : ", " }}
            </span>
          </ng-container>
        </div>
        <div class="row justify-content-center footer-desc mt-2">
          <div
            [ngClass]="
              plan?.description2?.length > 160 ? 'shadow-custom-desc' : ''
            "
            class="col-12 px-0"
          >
            <div
              class="description"
              [innerHTML]="plan.description2 | excerpt : 160"
            ></div>
          </div>
        </div>
        <div class="row text-start">
          <p
            *ngIf="plan.description2"
            (click)="openModal(plan)"
            class="see-more mt-4"
          >
            {{ "know more" | translate }}
          </p>
        </div>
      </div>

      <div
        class="col-md-4 col-lg-2 ammount d-flex flex-column justify-content-around align-items-center svg-image"
      >
        <span
          *ngIf="plan?.price != 0 && plan?.price_global != 0"
          class="interval-time"
          >{{ plan.showInterval | translate }}
        </span>

        <!--         
        <span *ngIf="plan.interval == 'day'" class="interval-time"
          >{{ plan.interval_count }}
          {{
            plan.interval_count == 1
              ? ("Day" | translate)
              : ("Days" | translate)
          }}</span
        >
        <span *ngIf="plan.interval == 'week'" class="interval-time"
          >{{ plan.interval_count }}
          {{
            plan.interval_count == 1
              ? ("Week" | translate)
              : ("Weeks" | translate)
          }}</span
        >
        <span *ngIf="plan.interval == 'year'" class="interval-time"
          >{{ plan.interval_count }}
          {{
            plan.interval_count == 1
              ? ("Year" | translate)
              : ("Years" | translate)
          }}</span
        >
        <span *ngIf="plan.interval == 'month'" class="interval-time"
          >{{ plan.interval_count }}
          {{
            plan.interval_count == 1
              ? ("Month" | translate)
              : ("Months" | translate)
          }}</span
        > -->

        <div class="price">
          <p class="total">{{ plan.price_description_final | translate }}</p>
          <p *ngIf="plan.global_price_description_final" class="global_price">
            {{ plan.global_price_description_final }}
          </p>
        </div>

        <img
          *ngIf="checkImagePath(plan?.icon?.small)"
          class="icon-img"
          [src]="plan.icon.small"
          decoding="async"
          loading="lazy"
          [srcset]="plan.icon | srcset"
          [alt]="plan.name + ' icon'"
        />
        <!-- <i *ngIf="plan.price==0" class="fas fa-gift icons"></i>
      <i *ngIf="plan.price>0" class="fas fa-user-graduate icons"></i> -->

        <div
          *ngIf="plan.selected || plan.price >= 0"
          class="plan-button mb-4 pointer"
          [class.pointer]="!plan.selected"
          (click)="selectPlan(plan)"
        >
          <span class="selected" *ngIf="plan.selected && !plan.selecting">
            {{ "Selected" | translate }}
          </span>
          <span
            class="select"
            *ngIf="
              !plan.selected &&
              !plan.selecting &&
              logged == 'true' &&
              plan.price > 0
            "
            >{{ "Select" | translate }}
          </span>
          <span
            class="select"
            *ngIf="!plan.selected && !plan.selecting && logged == 'false'"
            >{{ "Subscription" | translate }}
          </span>
          <span class="select" *ngIf="plan.selecting"
            >{{ "Selected" | translate }}
            <i class="fas fa-circle-notch fa-spin"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="typePlans == 'grid' && planByTime">
  <div *ngIf="plansByDay.length > 0" class="col-12 col-sm-6 offset-sm-3 mb-5">
    <h2
      [style.color]="company.home_font_color"
      class="titleTextFont pageTitle text-center"
    >
      {{ "Daily plans" | translate }}
    </h2>
  </div>
  <div *ngIf="plansByDay.length > 0" class="row">
    <div
      class="col-md-4 col-12 mb-2"
      *ngFor="let item of plansByDay.slice(0, 6)"
    >
      <div
        [ngStyle]="{
          'background-image': 'url(' + item?.background_image + ')',
          'background-repeat': 'no-repeat',
          'background-position': '50% -230px',
          'background-size': 'cover',
          height: '50%'
        }"
        class="card p-3 mx-auto"
        [class.selected]="item?.selected"
      >
        <div
          class="card-body text-center d-flex flex-column justify-content-between"
        >
          <h5 class="card-title mb-0" style="height: 70px">{{ item?.name }}</h5>
          <div class="row justify-content-center">
            <div class="col-auto mb-1 my-4">
              <img
                *ngIf="checkImagePath(item?.icon?.small)"
                [src]="item?.icon?.small"
                alt=""
                class="icon-plan"
              />
            </div>
          </div>
          <div class="card-princing">
            <div *ngIf="!item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global || 0 }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
            <div *ngIf="item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global || 0 }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
          </div>
          <div class="col" style="max-height: 10rem; min-height: 10rem">
            <p class="text-footer-card" *ngIf="!item.access_info">
              {{
                (item.price == 0
                  ? gender == "m"
                    ? "Allow all free courses"
                    : "Allow all free courses:f"
                  : gender == "m"
                  ? item.access.name
                  : "Allow All courses:f"
                )
                  | translate
                    : { courses: (course_text_s.toLowerCase() | translate) }
              }}
            </p>
            <p class="text-footer-card" *ngIf="item.access_info">
              {{ item.access_info }}
            </p>
            <div class="row justify-content-center footer-desc">
              <div
                [ngClass]="
                  item?.description?.length > 140 ? 'shadow-custom' : ''
                "
                class="col-10"
              >
                <div
                  class="desc"
                  [innerHTML]="
                    item.description
                      | excerpt : (item?.name?.length > 15 ? 110 : 140)
                  "
                ></div>
              </div>
            </div>
          </div>

          <p
            *ngIf="item.description"
            (click)="openModal(item)"
            class="see-more"
          >
            {{ "See more" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mx-auto">
        <button
          [disabled]="item?.selected"
          (click)="selectPlan(item)"
          type="button"
          class="openModal col-md-12 col-9 my-3"
        >
          {{ (item.selected ? "Your current plan" : "Start") | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="plansByWeek.length > 0" class="col-12 col-sm-6 offset-sm-3 my-5">
    <h2
      [style.color]="company.home_font_color"
      class="titleTextFont pageTitle text-center"
    >
      {{ "Weekly Plans" | translate }}
    </h2>
  </div>
  <div *ngIf="plansByWeek.length > 0" class="row">
    <div
      class="col-md-4 col-12 mb-2"
      *ngFor="let item of plansByWeek.slice(0, 6)"
    >
      <div
        [ngStyle]="{
          'background-image': 'url(' + item?.background_image + ')',
          'background-repeat': 'no-repeat',
          'background-position': '50% -230px',
          'background-size': 'cover',
          height: '50%'
        }"
        class="card p-3 mx-auto"
        [class.selected]="item?.selected"
      >
        <div
          class="card-body text-center d-flex flex-column justify-content-between"
        >
          <h5 class="card-title mb-0" style="height: 70px">{{ item?.name }}</h5>
          <div class="row justify-content-center">
            <div class="col-auto mb-1 my-4">
              <img
                *ngIf="checkImagePath(item?.icon?.small)"
                [src]="item?.icon?.small"
                alt=""
                class="icon-plan"
              />
            </div>
          </div>
          <div class="card-princing">
            <div *ngIf="!item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global || 0 }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
            <div *ngIf="item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
          </div>
          <div class="col" style="max-height: 10rem; min-height: 10rem">
            <p class="text-footer-card" *ngIf="!item.access_info">
              {{
                (item.price == 0
                  ? gender == "m"
                    ? "Allow all free courses"
                    : "Allow all free courses:f"
                  : gender == "m"
                  ? item.access.name
                  : "Allow All courses:f"
                )
                  | translate
                    : { courses: (course_text_s.toLowerCase() | translate) }
              }}
            </p>
            <p class="text-footer-card" *ngIf="item.access_info">
              {{ item.access_info }}
            </p>
            <div class="row justify-content-center">
              <div
                [ngClass]="
                  item?.description?.length > 140 ? 'shadow-custom' : ''
                "
                class="col-10"
              >
                <div
                  class=""
                  [innerHTML]="
                    item.description
                      | excerpt : (item?.name?.length > 15 ? 110 : 140)
                  "
                ></div>
              </div>
            </div>
          </div>

          <p
            *ngIf="item.description"
            (click)="openModal(item)"
            class="see-more"
          >
            {{ "See more" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mx-auto">
        <button
          [disabled]="item?.selected"
          (click)="selectPlan(item)"
          type="button"
          class="openModal col-md-12 col-9 my-3"
        >
          {{ (item.selected ? "Your current plan" : "Start") | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="plansByMonth.length > 0" class="col-12 col-sm-6 offset-sm-3 my-5">
    <h2
      [style.color]="company.home_font_color"
      class="titleTextFont pageTitle text-center"
    >
      {{ "Monthly plans" | translate }}
    </h2>
  </div>
  <div *ngIf="plansByMonth.length > 0" class="row">
    <div
      class="col-md-4 col-12 mb-2"
      *ngFor="let item of plansByMonth.slice(0, 6)"
    >
      <div
        [ngStyle]="{
          'background-image': 'url(' + item?.background_image + ')',
          'background-repeat': 'no-repeat',
          'background-position': '50% -230px',
          'background-size': 'cover',
          height: '50%'
        }"
        class="card p-3 mx-auto"
        [class.selected]="item?.selected"
      >
        <div
          class="card-body text-center d-flex flex-column justify-content-between"
        >
          <h5 class="card-title mb-0" style="height: 70px">{{ item?.name }}</h5>
          <div class="row justify-content-center">
            <div class="col-auto mb-1 my-4">
              <img
                *ngIf="checkImagePath(item?.icon?.small)"
                [src]="item?.icon?.small"
                alt=""
                class="icon-plan"
              />
            </div>
          </div>
          <div class="card-princing">
            <div *ngIf="!item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global || 0 }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
            <div *ngIf="item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
          </div>
          <div class="col" style="max-height: 10rem; min-height: 10rem">
            <p class="text-footer-card" *ngIf="!item.access_info">
              {{
                (item.price == 0
                  ? gender == "m"
                    ? "Allow all free courses"
                    : "Allow all free courses:f"
                  : gender == "m"
                  ? item.access.name
                  : "Allow All courses:f"
                )
                  | translate
                    : { courses: (course_text_s.toLowerCase() | translate) }
              }}
            </p>
            <p class="text-footer-card" *ngIf="item.access_info">
              {{ item.access_info }}
            </p>
            <div class="row justify-content-center">
              <div
                [ngClass]="
                  item?.description?.length > 140 ? 'shadow-custom' : ''
                "
                class="col-10"
              >
                <div
                  class="desc"
                  [innerHTML]="
                    item.description
                      | excerpt : (item?.name?.length > 15 ? 110 : 140)
                  "
                ></div>
              </div>
            </div>
          </div>

          <p
            *ngIf="item.description"
            (click)="openModal(item)"
            class="see-more"
          >
            {{ "See more" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mx-auto">
        <button
          [disabled]="item?.selected"
          (click)="selectPlan(item)"
          type="button"
          class="openModal col-md-12 col-9 my-3"
        >
          {{ (item.selected ? "Your current plan" : "Start") | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="plansByYear.length > 0" class="col-12 col-sm-6 offset-sm-3 my-5">
    <h2
      [style.color]="company.home_font_color"
      class="titleTextFont pageTitle text-center"
    >
      {{ "Annual Plans" | translate }}
    </h2>
  </div>
  <div *ngIf="plansByYear" class="row">
    <div
      class="col-md-4 col-12 mb-2"
      *ngFor="let item of plansByYear.slice(0, 6)"
    >
      <div
        [ngStyle]="{
          'background-image': 'url(' + item?.background_image + ')',
          'background-repeat': 'no-repeat',
          'background-position': '50% -230px',
          'background-size': 'cover',
          height: '50%'
        }"
        class="card p-3 mx-auto"
        [class.selected]="item?.selected"
      >
        <div
          class="card-body text-center d-flex flex-column justify-content-between"
        >
          <h5 class="card-title mb-0" style="height: 70px">{{ item?.name }}</h5>
          <div class="row justify-content-center">
            <div class="col-auto mb-1 my-4">
              <img
                *ngIf="checkImagePath(item?.icon?.small)"
                [src]="item?.icon?.small"
                alt=""
                class="icon-plan"
              />
            </div>
          </div>
          <div class="card-princing">
            <div *ngIf="!item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global || 0 }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
            <div *ngIf="item?.price_global" class="card-princing">
              <p class="mb-1">USD {{ item?.price_global }}</p>
              <span class="usd">{{ currency }} {{ item?.price }}</span>
            </div>
          </div>
          <div class="col" style="max-height: 10rem; min-height: 10rem">
            <p class="text-footer-card" *ngIf="!item.access_info">
              {{
                (item.price == 0
                  ? gender == "m"
                    ? "Allow all free courses"
                    : "Allow all free courses:f"
                  : gender == "m"
                  ? item.access.name
                  : "Allow All courses:f"
                )
                  | translate
                    : { courses: (course_text_s.toLowerCase() | translate) }
              }}
            </p>
            <p class="text-footer-card" *ngIf="item.access_info">
              {{ item.access_info }}
            </p>

            <div class="row justify-content-center">
              <div
                [ngClass]="
                  item?.description?.length > 140 ? 'shadow-custom' : ''
                "
                class="col-10"
              >
                <div
                  class=""
                  [innerHTML]="
                    item.description
                      | excerpt : (item?.name?.length > 15 ? 110 : 140)
                  "
                ></div>
              </div>
            </div>
          </div>

          <p
            *ngIf="item.description"
            (click)="openModal(item)"
            class="see-more"
          >
            {{ "See more" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mx-auto">
        <button
          [disabled]="item?.selected"
          (click)="selectPlan(item)"
          type="button"
          class="openModal col-md-12 col-9 my-3"
        >
          {{ (item.selected ? "Your current plan" : "Start") | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="typePlans == 'grid' && !planByTime">
  <div class="col-12 col-sm-6 offset-sm-3 mb-5">
    <h2
      *ngIf="typePlans == 'grid' && hideName"
      [style.color]="company.home_font_color"
      class="titleTextFont pageTitle text-center"
    >
      {{ "Plans" | translate }}
    </h2>
  </div>
  <div class="row">
    <div class="col-md-4 col-12 mb-3" *ngFor="let item of plans.slice(0, 6)">
      <div
        [ngStyle]="{
          'background-image': 'url(' + item?.background_image + ')',
          'background-repeat': 'no-repeat',
          'background-position': '50% -230px',
          'background-size': 'cover',
          height: '50%'
        }"
        class="card p-3 mx-auto"
        [class.selected]="item?.selected"
      >
        <div
          class="card-body text-center d-flex flex-column justify-content-between"
        >
          <h5 class="card-title mb-0" style="height: 70px">{{ item?.name }}</h5>
          <div class="row justify-content-center">
            <div class="col-auto mb-1 my-4">
              <img
                *ngIf="checkImagePath(item?.icon?.small)"
                [src]="item?.icon?.small"
                alt=""
                class="icon-plan"
              />
            </div>
          </div>
          <div *ngIf="!item?.price_global" class="card-princing">
            <p class="mb-1">USD {{ item?.price_global || 0 }}</p>
            <span class="usd">{{ currency }} {{ item?.price }}</span>
            <div
              *ngIf="item?.price != 0 && item?.price_global != 0"
              class="text-interval-card"
            >
              {{ item?.showInterval | translate }}
            </div>
          </div>
          <div *ngIf="item?.price_global" class="card-princing">
            <p class="mb-1">USD {{ item?.price_global }}</p>
            <span class="usd">{{ currency }} {{ item?.price }}</span>
            <div
              *ngIf="item?.price != 0 && item?.price_global != 0"
              class="text-interval-card"
            >
              {{ item?.showInterval | translate }}
            </div>
          </div>
          <div class="col" style="max-height: 10rem; min-height: 10rem">
            <p class="text-footer-card" *ngIf="!item.access_info">
              {{
                (item.price == 0
                  ? gender == "m"
                    ? "Allow all free courses"
                    : "Allow all free courses:f"
                  : gender == "m"
                  ? item.access.name
                  : "Allow All courses:f"
                )
                  | translate
                    : { courses: (course_text_s.toLowerCase() | translate) }
              }}
            </p>
            <p class="text-footer-card" *ngIf="item.access_info">
              {{ item.access_info }}
            </p>

            <div class="row justify-content-center">
              <div
                [ngClass]="
                  item?.description?.length > 140 ? 'shadow-custom' : ''
                "
                class="col-10"
              >
                <div
                  class=""
                  [innerHTML]="item.description | excerpt : 140"
                ></div>
              </div>
            </div>
          </div>

          <p
            *ngIf="item.description"
            (click)="openModal(item)"
            class="see-more"
          >
            {{ "See more" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mx-auto">
        <button
          [disabled]="item?.selected"
          (click)="selectPlan(item)"
          type="button"
          class="openModal col-md-12 col-9 my-3"
        >
          {{ (item.selected ? "Your current plan" : "Start") | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<div class="row border-top"></div>

<app-invoice-modal type="Plan" [ID]="plan?.id"></app-invoice-modal>
<div
  class="modal fade"
  id="modal-register"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Aviso Legal"
>
  <div
    class="modal-dialog-width modal-dialog modal-dialog-centered"
    role="document"
  >
    <div class="modal-content modal-register" style="border-radius: 20px">
      <div class="modal-body">
        <div class="row justify-content-end mr-1">
          <button
            type="button"
            class="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 text-cter onResponsiveTop">
              <app-signup-widget-form
                [plan_price]="plan_price"
                [plan_name]="plan_name"
                [plan_include]="plan_include"
                [plan_interval]="plan_interval"
                [plan_interval_count]="plan_interval_count"
                [plan]="plan_select"
                [idPlan]="plan_id"
              ></app-signup-widget-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-description-plan [item]="currentDesc"></app-description-plan>
