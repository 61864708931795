import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { InitProvider } from "src/app/init-provider";
import { CompanyModel } from "src/app/Models/CompanyModel";

@Component({
  selector: "app-lets-talk",
  templateUrl: "./LetsTalk.component.html",
  styleUrls: ["./LetsTalk.component.scss"],
})
export class LetsTalkComponent implements OnInit {
  user: any;
  userName: string;
  userEmail: string;
  currentUrl;
  @Input() whiteText = "false";
  company: CompanyModel;
  showCertificate: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private initProvider: InitProvider
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.user = this.authService.user;
    if (this.user) {
      this.userEmail =
        this.user.email.length < 26
          ? this.user.email
          : this.user.email.slice(0, 25) + "...";
      this.userName =
        this.authService.user.first_name +
        " " +
        this.authService.user.last_name;

      this.userName =
        this.userName.length < 24
          ? this.userName
          : this.userName.slice(0, 22) + "...";
    }
    //this.userName = this.authService.user ? (this.authService.user.first_name +' '+ this.authService.user.last_name): false;

    // if (!this.userName && this.authService.user) {
    //   // this.authService.logOutWithRedirect()
    // }
    this.authService.userData.subscribe((data) => {
      if (data) {
        this.userEmail =
          data.email.length < 26 ? data.email : data.email.slice(0, 25) + "...";
        this.userName = data.first_name + " " + data.last_name;

        this.user.hero_image = data.hero_image;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    const company = this.initProvider.getSettings();
    this.company = company;
    const json = JSON.parse(company.faq_json);
    this.showCertificate = json.show_certificate_item_in_menu ? json.show_certificate_item_in_menu : false;

  }

  logOut() {
    this.authService.logOut();
    window.location.href = "/home";
  }
}
