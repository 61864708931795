import { Component, HostListener, Input, OnInit } from "@angular/core";
import * as moment from "moment-timezone";
import { CompanyService } from "../../Services/company.service";
import { BlockModel } from "../../Interfaces/BlockModel";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-block-course-list",
  templateUrl: "./block-course-list.component.html",
  styleUrls: ["./block-course-list.component.scss"],
})
export class BlockCourseListComponent implements OnInit {
  @Input() block: BlockModel;
  @Input() company: any;
  @Input() slice: any;
  @Input() fromHome: boolean = false;

  showBtn: boolean = false;

  lesson_text: string;
  lesson_text_s: string;
  show_lesson_text: boolean;
  courses: Array<any> = [];
  Math = Math;
  currency = null;
  subtitleText: any;
  public isMobile: boolean = false;
  constructor(
    companyService: CompanyService,
    private router: Router,
    protected sanitizer: DomSanitizer
  ) {
    this.lesson_text = companyService.lesson_text;
    this.lesson_text_s = companyService.lesson_text_s;
    this.show_lesson_text = companyService.show_lesson_text;
    this.currency = companyService.company.currency;
  }

  ngOnInit() {
   
    if (this.block.courses?.length > 24) {
      this.showBtn = true;
    }
   

    if (this.slice) {
      this.courses = this.block.courses.splice(0, this.slice);
    } else {
      this.courses = this.block.courses;
    }
    // if (this.block?.subtitle?.substring(0, 3) == "<p>") {
    //   const restoDelTexto = this.block.subtitle.substring(3);
    //   this.subtitleText = this.sanitizer.bypassSecurityTrustHtml(
    //     '<p style="color:' + this.block.secondary_color + ';">' + restoDelTexto
    //   );
    // } else {
    // }
    this.subtitleText = this.block.subtitle;
  }
  hasTitleLink() {
    return this.block.title_url ? true : false;
  }
  gotToLink() {
    if (this.block.title_url) {
      window.location.href = this.block.title_url;
    }
  }
  goPlans() {
    this.router.navigate(["/logged/profile"], {
      queryParams: { section: "plans" },
    });
  }

  isDiscounted(course) {
    return course.discount_has && moment(course.discount_until) > moment();
  }

  @HostListener("window:resize")
  onResize() {
    this.isMobile = matchMedia("(max-width: 767px)").matches;
  }
}
