import {Component, Input, OnInit,HostListener} from '@angular/core';
import {BlockModel} from 'src/app/Interfaces/BlockModel';
import ImageModel from '../../Interfaces/ImageModel';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-block-sliders-grid',
  templateUrl: './block-sliders-grid.component.html',
  styleUrls: ['./block-sliders-grid.component.scss']
})
export class BlockSlidersGridComponent implements OnInit {
  @Input() block: BlockModel;
  isMobile: boolean = false;
  subtitleText:any;

  slider: Array<{
    title: string,
    description: string,
    image: ImageModel,
    url: string,
   
  }> = [];

  constructor( protected sanitizer: DomSanitizer,) {
  }
  @HostListener('window:resize', ['$event'])

  onResize(event: Event): void {
    this.checkScreenWidth();
  }

  ngOnInit(): void {
    if (this.block.sliders) {
      this.slider = this.block.sliders;
    }
    console.log(this.slider, this.block);
    this.checkScreenWidth();
    if (this.block.subtitle?.substring(0, 3)=='<p>'){ 
      const restoDelTexto = this.block.subtitle?.substring(3);
      this.subtitleText=this.sanitizer.bypassSecurityTrustHtml('<p style="color:'+this.block.secondary_color+';">'+ restoDelTexto);
    }else{
      this.subtitleText=this.block.subtitle;
    }

  }
  hasTitleLink(){
    return this.block.title_url?true:false;
  }
  gotToLink(){
    if (this.block.title_url){
      window.location.href = this.block.title_url;
    }
  }
  getImage(item){
    let image=(this.isMobile&&item.mobile_image)?item.mobile_image.large:item.image.large;
    if (image.includes('generic.png')) {
     image=item.image.large;
    }
    return image;
  }
  
  checkScreenWidth(): void {
    this.isMobile = window.innerWidth <= 768; // Cambia el valor según tu criterio
    console.log('this.isMobile',this.isMobile)
  }

}
