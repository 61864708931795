import {Component, OnInit} from '@angular/core';
import {MenuItems} from '../../../Core/Menu/MenuItems/MenuItems';
import {Router, NavigationEnd} from '@angular/router';
import {DropletService} from '../../../Services/Droplet.service';
import {InitProvider} from '../../../init-provider';

@Component({
  selector: 'app-footer-one',
  templateUrl: './FooterOne.component.html',
  styleUrls: ['./FooterOne.component.scss']
})
export class FooterOneComponent implements OnInit {
  currentUrl: any;
  company: any;
  faq: any;
  currentYear: any;

  constructor(
    private router: Router,
    public service: DropletService,
    public initProvider: InitProvider
  ) {
    // social icons content
    this.company = this.initProvider.getSettings();
    this.faq = JSON.parse(this.company.faq_json);
    this.currentYear = (new Date()).getFullYear();
  }

  ngOnInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
        }
      });
    this.currentUrl = this.router.url;
  }
}
