<footer
*ngIf="!isOnSpecificRoute()"
  [style]="{
    background: company.footer.background_color,
    color: company.footer.menu_color
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12 footer-menu-container">
        <app-header-menu
          stickyClass="flex-row"
          [menuColor]="company.footer.menu_color"
        >
          <li [style]="{ color: company.footer.menu_color }">
            {{ company.name }}
          </li>
        </app-header-menu>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <app-theme-light-logo logoClass="navbar-brand"></app-theme-light-logo>
      </div>
      <div class="col-md-5">
        <div>
          <p *ngIf="company.footer.address">
            <a
              [style]="{ color: company.footer.menu_color }"
              [href]="generateGoogleMapsUrl(company.footer.address)"
              >{{ company.footer.address }}</a
            >
          </p>
          <p *ngIf="company.footer.phone">
            <a
              [style]="{ color: company.footer.menu_color }"
              *ngIf="validPhoneNumber(company.footer.phone); else elseBlock"
              [href]="'tel:' + parsePhoneNumber(company.footer.phone)"
              >{{ company.footer.phone }}</a
            >
            <ng-template #elseBlock>
              <span [style]="{ color: company.footer.menu_color }">{{
                company.footer.phone
              }}</span>
            </ng-template>
          </p>
          <p *ngIf="company.footer.email">
            <a
              [style]="{ color: company.footer.menu_color }"
              [href]="'mailto:' + company.footer.email"
              >{{ company.footer.email }}</a
            >
          </p>
          <p *ngIf="company.footer.url">
            <a
              [style]="{ color: company.footer.menu_color }"
              [href]="company.footer.url"
              >{{ company.footer.url }}</a
            >
          </p>
        </div>
        <div class="social-networks">
          <a
            [href]="company.footer.facebook"
            target="_blank"
            class="facebook"
            *ngIf="company.footer.facebook"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            [href]="company.footer.twitter"
            target="_blank"
            class="twitter"
            *ngIf="company.footer.twitter"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            [href]="company.footer.instagram"
            target="_blank"
            class="instagram"
            *ngIf="company.footer.instagram"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a
            [href]="company.footer.youtube"
            target="_blank"
            class="youtube"
            *ngIf="company.footer.youtube"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-youtube"></i>
          </a>
          <a
            [href]="company.footer.linkedin"
            target="_blank"
            class="linkedin"
            *ngIf="company.footer.linkedin"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a
            [href]="company.footer.twitch"
            target="_blank"
            class="twitch"
            *ngIf="company.footer.twitch"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-twitch"></i>
          </a>
          <a
            [href]="company.footer.tiktok"
            target="_blank"
            class="tiktok"
            *ngIf="company.footer.tiktok"
            [style.color]="company.footer.menu_color"
          >
            <i class="fab fa-tiktok"></i>
          </a>
          <a
            [href]="company.footer.web"
            target="_blank"
            class="web"
            *ngIf="company.footer.web"
            [style.color]="company.footer.menu_color"
          >
            <i class="fa fa-globe"></i>
          </a>
        </div>
      </div>
      <div class="col-md-4 logo-container">
        <ng-container *ngFor="let index of [1, 2, 3, 4, 5]">
          <div
            class="extra-logo"
            *ngIf="
              company.footer['extra_logo_image_' + index] &&
              !isGenericImage(company.footer['extra_logo_image_' + index])
            "
          >
            <a
              [href]="company.footer['extra_logo_url_' + index]"
              target="_blank"
              *ngIf="company.footer['extra_logo_url_' + index]; else noUrlBlock"
            >
              <img
                [src]="company.footer['extra_logo_image_' + index]?.medium"
                [srcset]="company.footer['extra_logo_image_' + index] | srcset"
                alt="extra logo"
                class="rounded-circle"
                width="36"
                height="36"
              />
              <div
                [style]="{ color: company.footer.menu_color }"
                *ngIf="company.footer['extra_logo_text_' + index]"
              >
                {{ company.footer["extra_logo_text_" + index] }}
              </div>
            </a>
            <ng-template #noUrlBlock>
              <img
                [src]="company.footer['extra_logo_image_' + index]?.medium"
                [srcset]="company.footer['extra_logo_image_' + index] | srcset"
                alt="extra logo"
                class="rounded-circle"
                width="36"
                height="36"
              />
              <div
                [style]="{ color: company.footer.menu_color }"
                *ngIf="company.footer['extra_logo_text_' + index]"
              >
                {{ company.footer["extra_logo_text_" + index] }}
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="float-right mt-3">
        <p
          class="copyRight mb-0 mt-1"
          [ngStyle]="{ color: company.footer.menu_color }"
        >
          ©{{ currentYear }} {{ company.name }} <span class="ml-2 mr-2">-</span>
          <a
            href="#"
            [ngStyle]="{ color: company.footer.menu_color }"
            data-toggle="modal"
            data-target="#modal-tac"
            >{{ "Terms and Conditions" | translate }}</a
          >
          <span *ngIf="company.legal_notice" class="ml-2 mr-2">-</span>
          <a
            *ngIf="company.legal_notice"
            href="#"
            [ngStyle]="{ color: company.footer.menu_color }"
            data-toggle="modal"
            data-target="#modal-ln"
            >{{ "Legal Notice" | translate }}</a
          >
          <span *ngIf="company.cookies_warning" class="ml-2 mr-2">-</span>
          <a
            *ngIf="company.cookies_warning"
            href="#"
            [ngStyle]="{ color: company.footer.menu_color }"
            data-toggle="modal"
            data-target="#modal-cookie"
            >{{ "Cookies Warning" | translate }}</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>

<div
  class="modal fade"
  id="modal-tac"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Términos y Condiciones"
  aria-hidden="true"
>
  <div class="modal-width modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px">
      <div class="modal-header">
        <h4 class="mb-0">{{ "Terms and Conditions" | translate }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ company.term_and_conditions }}
      </div>
    </div>
  </div>
</div>

<!-- Legal Notice Modal -->
<div
  class="modal fade"
  id="modal-ln"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Aviso Legal"
  aria-hidden="true"
>
  <div class="modal-width modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px">
      <div class="modal-header">
        <h4 class="mb-0">{{ "Legal Notice" | translate }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ company.legal_notice }}
      </div>
    </div>
  </div>
</div>

<!-- Cookie Notice Modal -->
<div
  class="modal fade"
  id="modal-cookie"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Cookie Police"
  aria-hidden="true"
>
  <div class="modal-width modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px">
      <div class="modal-header">
        <h4 class="mb-0">{{ "Cookies Warning" | translate }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ company.cookies_warning }}
      </div>
    </div>
  </div>
</div>
