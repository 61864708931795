import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {InitProvider} from 'src/app/init-provider';
import * as moment from 'moment';

@Component({
  selector: "slider-grid-card",
  templateUrl: "./slider-grid-card.component.html",
  styleUrls: ["./slider-grid-card.component.scss"],
})
export class SliderGridCardComponent implements OnInit {

  @Input() slider: any;
  currency = null;
  Math = Math;
  constructor(
    private router: Router,  
    private initProvider: InitProvider,
  ) { }

  ngOnInit(): void {
    const company = this.initProvider.getSettings();
    this.currency = company.currency;
    console.log('grid', this.slider)
  }

  goToProfile(slider) {
   // console.log(slider)
   if (slider.url){
     window.location.href = slider.url;
   }
  }
 
  
     
}
