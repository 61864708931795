<div
  class="modal fade"
  id="modal-description"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Aviso Legal"
  data-keyboard="false"
  data-backdrop="static"
>
  <div
    class="modal-dialog-width modal-dialog modal-dialog-centered"
    role="document"
  >
    <div class="modal-content modal-timezone" style="border-radius: 20px">
      <div class="modal-body">
        <div class="row justify-content-end mr-1">
          <button
            type="button"
            class="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-12 mt-2 text-center onResponsiveTop mb-3">
              <h2 class="text-info text-info-timezone">{{ item?.name }}</h2>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-11 text-start">
              <div
                class="font-bigger"
                style="font-size: 1.2rem"
                [innerHTML]="item?.description"
              ></div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-12 my-5 text-center onResponsiveTop row-width">
              <div class="col-md-10 offset-md-1 text-center">
                <button
                  (click)="closeModal()"
                  class="btn btn-info mt-0 btn-accept"
                >
                  {{ "Iniciar" | translate }}
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
