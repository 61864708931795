import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "../../Services/Api.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { InitProvider } from "../../init-provider";
import { ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/filter";
import _ from "lodash";

declare var $;

@Component({
  selector: "app-pricing-list",
  templateUrl: "./pricing-list.component.html",
  styleUrls: ["./pricing-list.component.scss"],
})
export class PricingListComponent implements OnInit {
  @Input() showTrial: any;
  @Input() logged = "true";
  @Input() hideName = false;

  currentDesc = {};
  plans = [];
  user: any = {};
  errName = false;
  errEmail = false;
  errDni = false;
  saveLoading = false;
  currentUser;
  plan;
  plan_select = null;
  plan_id = null;
  plan_name = "";
  plan_price: any = "";
  plan_include: "";
  plan_interval: "";
  plan_interval_count: "";
  view_free = false;
  plan_access = "";
  course_text = "Course";
  lesson_text = "Unit";
  course_text_s;
  lesson_text_s;
  company: any;
  free_plan = "free_include";
  priceDescription = "";

  public typePlans = "";
  public planByTime = "";
  public plansByDay: any = [];
  public plansByWeek: any = [];
  public plansByMonth: any = [];
  public plansByYear: any = [];
  public currency = "";
  public gender = "m";

  constructor(
    private apiService: ApiService,
    public authService: AuthService,
    public initProvider: InitProvider,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const user = this.authService.user;
    const company = this.initProvider.getSettings();
    const json = JSON.parse(company?.faq_json);
    this.gender = json?.texts?.course_gender || "m";

    this.currentUser = user;

    this.user = JSON.parse(localStorage.getItem("user"));
    this.company = this.initProvider.getSettings();
    this.typePlans = this.company?.view_plans_visualization;
    this.planByTime = this.company?.view_plans_by_time;
    this.currency = this.company?.currency?.code;
    this.get();
  }

  get() {
    this.apiService.get("plans?active=true").then((res) => {
      this.transform(res.data);
      this.transformTextAccessPlan(res.data);
      this.plans = res.data;
      // this.plan_access=res.data.access.name;
      this.plans.forEach((plan: any) => {
        //  console.log('(plan.interval',plan.interval)
        if (plan.interval === "day") {
          plan.showInterval = "daily";
          this.plansByDay.push(plan);
        }
        if (plan.interval === "week") {
          plan.showInterval = "weekly";

          this.plansByWeek.push(plan);
        }
        if (plan.interval === "month") {
          if (plan.interval_count == 3) {
            plan.showInterval = "quarterly";
          }
          if (plan.interval_count == 1) {
            plan.showInterval = "monthly";
          }
          if (plan.interval_count == 6) {
            plan.showInterval = "semiannual";
          }

          this.plansByMonth.push(plan);
        }
        if (plan.interval === "year") {
          plan.showInterval = "annual";
          this.plansByYear.push(plan);
        }
      });
      this.route.queryParams.subscribe((params) => {
        if (params && params.plan && params?.id) {
          const plan = _.find(this.plans, (item) => {
            return params.plan === item.key || params?.id == item.id;
          });
          if (plan) {
            this.selectPlan(plan);
          }
        }
      });
    });
  }

  transform(plans) {
    plans.forEach((plan) => {
      plan.price_description_final =
        plan.price != 0
          ? this.company.currency.code + " " + plan.price
          : "Free";
      plan.global_price_description_final = plan.price_global
        ? "USD " + plan.price_global
        : null;
      if (
        this.user !== null &&
        this.user.plan !== null &&
        plan.id === this.user.plan.id
      ) {
        plan.selected = true;
        plan.selecting = false;
      }
    });
  }

  transformTextAccessPlan(plans) {
    const json = JSON.parse(this.company.faq_json);
    let company_language = "es";

    if (this.user) {
      company_language = this.user.language;
    } else {
      company_language = this.company.language;
    }

    console.log("company_language", company_language);
    // console.log(json.texts.course);
    this.course_text = "course";
    this.lesson_text = "unit";
    this.course_text_s = "courses";
    this.lesson_text_s = "unit";

    if (json.texts) {
      if (company_language === "en") {
        this.course_text = json.texts.course_en
          ? json.texts.course_en
          : "course";
        this.lesson_text = json.texts.lesson_en ? json.texts.lesson_en : "unit";
        this.course_text_s = json.texts.courses_en
          ? json.texts.courses_en
          : "courses";
        this.lesson_text_s = json.texts.lessons_en
          ? json.texts.lessons_en
          : "unit";
      }
      if (company_language === "es") {
        this.course_text = json.texts.course_es
          ? json.texts.course_es
          : "Curso";
        this.lesson_text = json.texts.lesson_es
          ? json.texts.lesson_es
          : "Unidad";
        this.course_text_s = json.texts.courses_es
          ? json.texts.courses_es
          : "Cursos";
        this.lesson_text_s = json.texts.lessons_es
          ? json.texts.lessons_es
          : "Unidades";
      }
      if (company_language === "it") {
        this.course_text = json.texts.course_it
          ? json.texts.course_it
          : "Course";
        this.lesson_text = json.texts.lesson_it ? json.texts.lesson_it : "Unit";
        this.course_text_s = json.texts.courses_it
          ? json.texts.courses_it
          : "Courses";
        this.lesson_text_s = json.texts.lessons_it
          ? json.texts.lessons_it
          : "Unit";
      }
    }

    this.free_plan = "Todos los Senderos y Eventos gratuitos";
  }

  selectPlan(plan) {
    if (this.logged == "false") {
      $("#modal-register").modal("show");
      console.log(plan);
      this.plan_id = plan.id;
      this.plan_name = plan.name;

      this.plan_price =
        plan.price_description_final +
        (plan.global_price_description_final
          ? " | " + plan.global_price_description_final
          : " ");
      this.plan_include = plan.access.name;
      this.plan_interval_count = plan.interval_count;
      this.plan_interval = plan.interval;

      this.plan_select = plan;
      return;
    }
    if (plan.selected || plan.price === 0) {
      return;
    }
    console.log("Plans");
    console.log("Datos del usuario");
    const user = this.authService.user;
    this.currentUser = user;
    console.log(this.currentUser);

    if (this.company.request_billing_info) {
      console.log("Company necesita datos de facturación");
      if (
        !user.invoice_name ||
        !user.invoice_email ||
        !user.invoice_dni ||
        !user?.phone_number_invoice
      ) {
        console.log("Falta un dato");
        $("#modal-timezone").modal("show");
        this.plan = plan;
        return;
      }
    }

    this.router.navigate(["/purchases", "plan", plan.id]);
  }

  async onSaveProfile() {
    if (this.plan.selected || this.plan.price === 0) {
      return;
    }
    console.log("saved this22!!!", this);
    this.saveLoading = true;
    this.errName = false;
    this.errEmail = false;
    this.errDni = false;
    if (!this.currentUser.invoice_name) {
      console.log("no tiene nombre");
      this.errName = true;
    }

    if (!this.currentUser.invoice_dni) {
      this.errDni = true;
    }

    if (!this.currentUser.invoice_email) {
      this.errEmail = true;
    }

    if (this.errEmail || this.errDni || this.errName) {
      return;
    }
    const saved = await this.apiService
      .put("users/" + this.currentUser.id, {
        invoice_name: this.currentUser.invoice_name,
        invoice_dni: this.currentUser.invoice_dni,
        invoice_address: this.currentUser.invoice_address,
        invoice_giro: this.currentUser.invoice_giro,
        invoice_razon_social: this.currentUser.invoice_razon_social,
        invoice_email: this.currentUser.invoice_email,
      })
      .catch((err) => err);

    if (saved.error) {
      if (saved.error.errors.email) {
        return alert(saved.error.errors.email[0]);
      } else if (saved.error.errors.first_name) {
        return alert(saved.error.errors.first_name[1]);
      } else if (saved.error.errors.last_name) {
        return alert(saved.error.errors.last_name[1]);
      }
    }
    console.log("aaa saved!", saved);
    console.log(saved);
    this.authService.saveUserData();
    this.saveLoading = false;
    $("#modal-timezone").modal("hide");
    console.log("redirigiendo al lugar correcto.");
    this.router.navigate(["/purchases", "plan", this.plan.id]);
    // this.router.navigate(['/logged/profile/payment'], {queryParams: {plan: this.plan.id}});
  }
  public openModal(item) {
    this.currentDesc = item;
    $("#modal-description").modal("show");
  }
  public closeModal() {
    this.currentDesc = "";
    $("#modal-description").modal("hide");
  }

  checkImagePath(inputString) {
    const targetString = "/generic.png";
    const responseString = "";
    if (inputString.includes(targetString)) {
      return responseString;
    } else {
      return inputString;
    }
  }
}
