import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InitProvider} from '../init-provider';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public baseUrl: string;
  public configUrl: string;
  public getIpUrl: string;
  public resolveLinkUrl: string;

  constructor(
    private router: Router,
    public httpClient: HttpClient,
    initProvider: InitProvider
  ) {
    this.baseUrl = initProvider.getSettings().api_url + '/';
  }

  public getHeaders(): {} {
    const headers = {};
    const token = localStorage.getItem('access_token');
    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

  public get(url: string): Promise<any> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.httpClient
      .get(this.baseUrl.concat(url), options)
      .toPromise()
      .catch(error => {
        if (error.status === 500 && error.error && error.error.message.includes('Unauthenticated')) {
          // force logout
          localStorage.clear();
           window.location.href = '/home?expired=true';
          // this.router.navigate(['home']);
        }

        console.log(error);
      });
  }

  public post(url: string, data = {}): Promise<any> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.httpClient
      .post(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public put(url: string, data): Promise<any> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.httpClient
      .put(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public delete(url: string, data = ''): Promise<object> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.httpClient
      .delete(this.baseUrl.concat(url).concat(data), options)
      .toPromise();
  }

  getConfig(): Promise<any> {
    return this.get(this.configUrl);
  }

  getProductById(id) {
    return this.get(('products/').concat(id));
  }

}
