import {Component, OnInit} from '@angular/core';
import {InitProvider} from '../../init-provider';
import {BlocksService} from 'src/app/Services/blocks.service';
import { ActivatedRoute, Router } from "@angular/router";
import {BlockModel} from '../../Interfaces/BlockModel';

@Component({
  selector: 'app-page-block',
  templateUrl: './Pages-block.component.html',
  styleUrls: ['./Pages-block.component.scss']
})
export class PagesBlockComponent implements OnInit {
 
  company: any;  
  blocks: Array<BlockModel> = [];
  Math = Math;
  whatsapp_number = '';
  company_email='';
  company_wp=null;
  phoneImageUrl = '';
  desktopImageUrl = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    public initProvider: InitProvider,    
    public blocksService: BlocksService,
    private router: Router
  ) {
    this.company = initProvider.getSettings();   
  }


  ngOnInit() {
    this.desktopImageUrl = this.company?.home_image;
    this.phoneImageUrl = this.company?.home_image_mobile;
   
    this.company_email= this.company?.email;
    this.whatsapp_number = this.company?.whatsapp_number;
    this.activatedRoute.paramMap.subscribe((params) => {
      let slug= params.get("slug")
      if (!slug) {
        this.router.navigate(['/']);
      }else{
        this.blocksService.getPage(slug).then((page) => {
          let id=page.data.id
          this.getBlocks(id);
        }).catch((error) => {
           
          this.router.navigate(['/']);
    
        });
      }
    
    })


  
    
  }
 

  


  
  getBlocks(id) {
    this.blocksService.getBlocks(id).then((blocks) => {
      //this.blocks = blocks.data;
      this.blocks = blocks.data.filter(block => {
        if ((block.type=='product'&&block.products&&block.products.length==0)||block.type=='product'&&!block.products){
          return false
        }
        if ((block.type=='event'&&block.events&&block.events.length==0)||block.type=='event'&&!block.events){
          return false
        }
        if ((block.type=='program'&&block.programs&&block.programs.length==0)||block.type=='program'&&!block.programs){
          return false
        }
        if ((block.type=='course'&&block.courses&&block.courses.length==0)||block.type=='course'&&!block.courses){
          return false
        }
        return true
          
        
        return !["product", "curses", "programa", "eventos"].includes(block.type) || block.count > 0;
      });
      
      
    }).catch((error) => {
      console.log(error);
    });
  }




}
