<div class="container-fluid">
  <div *ngIf="block.show_titles" class="row" style="padding:2rem 0;">
    <div class="col-12">
      <h2 class="titleTextFont font-weight-normal text-center" style="font-size: 2.4rem;"
          [ngStyle]="{'color':block.titles_color?block.titles_color:''}"
          [ngClass]="{'cursor-pointer': hasTitleLink()}"
          (click)="gotToLink()">
        {{ block.title }}
      </h2>
    </div>
    <div *ngIf="block.subtitle" class="col-12">
      <h2 class="font-weight-normal fadeIn text-center mb-3 subtitle-block" style="font-size: 1.5rem;"
          [ngStyle]="{'color':block.secondary_color?block.secondary_color:''}"
          [innerHtml]="subtitleText"
         
          >
        
      </h2>
    </div>
  </div>
  <div
    *ngIf="slider && slider.length > 0"
    class="carousel row slide"
    data-ride="carousel"
    [id]="'block-slider-' + block.id"
  >
    <div class="carousel-inner">
      <div
        *ngFor="let item of slider; let i = index"
        class="carousel-item"
        [class.active]="i === 0"
      >
        <a [href]="item.url">
          <img
            [src]="getImage(item)"
            sizes="100vw"
            class="d-block w-100"
            alt=""
            loading="lazy"
            decoding="async"
          />
          <div
            class="carousel-caption d-md-block"
            style="padding: 2rem"
            *ngIf="item.title || item.description"
          >
            <h4
              class="text-secondary"
              *ngIf="item.title"
            >
              {{ item.title }}
            </h4>
            <p class="text-secondary" style=" font-size: 1.3rem" *ngIf="item.description">
              {{ item.description }}
            </p>
            <div class="overlay-slider-custom"></div>
          </div>
        </a>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      [href]="'#block-slider-' + block.id"
      role="button"
      data-slide="prev"
    >
      <!--    <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
      <div>
        <button class="btn btnRound"><img src="assets/images/mootiva/left-arrow.svg" width="40" height="40"
                                          alt="Left" class="mr-1" decoding="async" loading="lazy"></button>
      </div>

      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      [href]="'#block-slider-' + block.id"
      role="button"
      data-slide="next"
    >
      <!--    <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
      <div>
        <button class="btn btnRound"><img src="assets/images/mootiva/right-arrow.svg" width="40" height="40"
                                          alt="Right" class="ml-1" decoding="async" loading="lazy"></button>
      </div>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
