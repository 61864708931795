import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { LandingService } from "src/app/Services/landing.service";
import { InitProvider } from "src/app/init-provider";

@Component({
  selector: "app-thank-you-landing",
  templateUrl: "./thank-you-landing.component.html",
  styleUrls: ["./thank-you-landing.component.scss"],
})
export class ThankYouLandingComponent implements OnInit, AfterViewInit {
  public infoLanding: any = {};
  public videoLoaded = false;
  public company: any;

  constructor(
    private ladingService: LandingService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    public initProvider: InitProvider
  ) {
    this.infoLanding = this.ladingService.currenLanding;
    this.company = initProvider.getSettings();
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
 
  }
  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return "";
    }
    results = url.match("[\\?&]v=([^&#]*)");
    video = results === null ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/" + video
    );
  }
  openVideoModal(videoID) {
    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  initializeVideoPlayer(videoID) {
    this.videoLoaded = true;
    $("#modal-video").on("hide.bs.modal", () => {
      this.videoLoaded = false;
      this.changeDetectorRef.detectChanges();
    });
  }
}
