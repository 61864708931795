<div class="header-widget-area" style="display: flex" *ngIf="userName" [class.white]="whiteText">
  <div class="dropdown d-none d-lg-block ">
    <img [src]="user?.hero_image?.small"  onError="this.src = 'assets/images/avatar-default.png'" loading="lazy" decoding="async" alt="" width="42" height="42" class="profile-button"
         data-toggle="dropdown">
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <div class="dropdown-header">
        <img [src]="user?.hero_image?.small" onError="this.src = 'assets/images/avatar-default.png'" loading="lazy" decoding="async" alt="" width="72" height="72" class="profile-button large">
        <div>
          <p style="font-size: 1rem !important;" class="font-weight-bold profile-menu-username">{{ userName }}</p>
          <p style="font-size: 1rem !important;" class="text-muted profile-menu-email">{{userEmail }}</p>
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" routerLink="/logged/profile" [queryParams]="{section:'profile'}">{{ 'Profile' | translate}}</a>
      <a class="dropdown-item" routerLink="/logged/profile" [queryParams]="{section:'plans'}">{{ 'Plans' | translate}}</a>
      <!-- <a class="dropdown-item" routerLink="/logged/ia-messages" >{{ 'Mensajes' | translate}}</a> -->
      <a *ngIf="showCertificate" class="dropdown-item" href="/certificates" >{{ 'Certificates' | translate}}</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" routerLink="/logged/faqs">{{ 'Support' | translate}}</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">Salir</a>
    </div>
  </div>
</div>
<div class="header-widget-area" style="display: flex" *ngIf="!userName">
  <div class="lets-talk-link">
    <a href="javascript:void(0)" [routerLink]="['/login']" class="btn btn-info"> {{ 'LOGIN' | translate }}  </a>
  </div>
</div>
<!-- header-widget-area wrap close -->
