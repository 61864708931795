import {Component, Input, OnInit} from '@angular/core';
import {BlockModel} from '../../Interfaces/BlockModel';
import {CompanyModel} from '../../Models/CompanyModel';
import {CompanyService} from '../../Services/company.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-block-products',
  templateUrl: './block-products.component.html',
  styleUrls: ['./block-products.component.scss']
})
export class BlockProductsComponent implements OnInit {
 
  @Input() block: BlockModel;
  @Input() company: CompanyModel;
  @Input() slice: number;
  @Input() logged: boolean = false;

  subtitleText:any;
  productList: Array<any>;
  currency: any;

  constructor(
    companyService: CompanyService,
    protected sanitizer: DomSanitizer,
  ) {
    this.currency = companyService.company.currency;
  }

  ngOnInit(): void {

    if (this.block.products) {
      this.productList = this.block.products.slice(0, this.slice);
      if (this.block.subtitle&&this.block.subtitle?.substring(0, 3)=='<p>'){ 
        const restoDelTexto = this.block.subtitle?.substring(3);
        this.subtitleText=this.sanitizer.bypassSecurityTrustHtml('<p style="color:'+this.block.secondary_color+';">'+ restoDelTexto);
      }else{
        this.subtitleText=this.block.subtitle;
      }
    }
    
  }

  hasTitleLink(){
    return this.block.title_url?true:false;
  }
  gotToLink(){
    if (this.block.title_url){
      window.location.href = this.block.title_url;
    }
  }

  programLink(program: any) {
    return ['/programs/profile', program.id];
    if (this.logged) {
    }
    return ['/block', this.block.id];
  }
}
