<a
  *ngIf="whatsapp_show_icon&&whatsapp_number"
  target="_blank"
  [attr.href]="'https://wa.me/' + whatsapp_number"
  class="btn btn-round btn-fab btn-fab__wapp"
>
  <img
    src="assets/images/whatsapp.png"
    alt="whatsapp"
    loading="lazy"
    decoding="async"
    class="img_icon_wp"
  />
</a>


<a
  *ngIf="instagram_show_icon&&instagram"
  target="_blank"
  [attr.href]="instagram"
  class="btn btn-round btn-instagram btn-fab__inst"
>
   
  <img
    class="img_icon"
    src="assets/images/insgram_icon.png"
    alt="whatsapp"
    loading="lazy"
    decoding="async"
 
/>
   
</a>


<a
  *ngIf="facebook_show_icon&&facebook"
  target="_blank"
  [attr.href]="facebook"
  class="btn btn-round btn-facebook btn-fab__facebook"
>
<img
    class="img_icon"
    src="assets/images/facebook_icon.svg"
    alt="whatsapp"
    loading="lazy"
    decoding="async"

/>
</a>
<div [dir]="isRtl" (scroll)="onScrollEvent($event)" class="app-wrapper">
  <app-header-one></app-header-one>
  <app-banner-plan></app-banner-plan>
  <!-- header close -->
  <ng-container *ngIf="currentUrl == '/home'">
    <!--   <app-home-banner></app-home-banner>
	    banner sec close -->
  </ng-container>
  <!-- rtl Layout close  -->
  <div class="container-router">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  <ng-container>
    <!--		<app-footer-one></app-footer-one>-->
    <app-footer></app-footer>
  </ng-container>
  <!-- footer close-->
</div>
<!-- rtl wrap close -->
