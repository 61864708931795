import {Component, Input, OnInit} from '@angular/core';
import {BlockModel} from '../../Interfaces/BlockModel';
import {CompanyModel} from '../../Models/CompanyModel';
import {CompanyService} from '../../Services/company.service';
import { AuthService } from "src/app/Services/Auth/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-block-events',
  templateUrl: './block-events.component.html',
  styleUrls: ['./block-events.component.scss']
})
export class BlockEventsComponent implements OnInit {
  @Input() block: BlockModel;
  @Input() company: CompanyModel;
  @Input() slice: number;
  @Input() logged: boolean = false;
  subtitleText:any;
  eventList: Array<any>;
  currency: any;

  constructor(
    protected sanitizer: DomSanitizer,
    companyService: CompanyService,
    public authService: AuthService,
  ) {
    this.currency = companyService.company.currency;
  }

  ngOnInit(): void {
    console.log(this.block);
    if (this.block.events) {
      this.eventList = this.block.events.slice(0, this.slice);
    }
    if (this.block?.subtitle?.substring(0, 3)=='<p>'){ 
      const restoDelTexto = this.block?.subtitle?.substring(3);
      this.subtitleText=this.sanitizer.bypassSecurityTrustHtml('<p style="color:'+this.block.secondary_color+';">'+ restoDelTexto);
    }else{
      this.subtitleText=this?.block?.subtitle;
    }
  }
  hasTitleLink(){
    return this.block.title_url?true:false;
  }
  gotToLink(){
    if (this.block.title_url){
      window.location.href = this.block.title_url;
    }
  }
  parseEventStartTime(event) {
    let dateUTC=null;
    if (this.authService.isAuthenticated()){
       dateUTC = moment(event.start_date.date);
    }else{
       dateUTC = moment.utc(event.start_date.date);

    }
    return dateUTC.local().format('HH:mm');
  }

  parseEventEndTime(event) {
    let dateUTC=null;
    if (this.authService.isAuthenticated()){
       dateUTC = moment(event.end_date.date);
    }else{
       dateUTC = moment.utc(event.end_date.date);

    }

    return dateUTC.local().format('HH:mm');
  }

  parseEventDateNumber(event) {
    let dateUTC=null;
    if (this.authService.isAuthenticated()){
       dateUTC = moment(event.start_date.date);
    }else{
       dateUTC = moment.utc(event.start_date.date);

    }

   
    return dateUTC.local().date();
  }

  parseEventMonth(event) {
    let dateUTC=null;
    if (this.authService.isAuthenticated()){
       dateUTC = moment(event.start_date.date);
    }else{
       dateUTC = moment.utc(event.start_date.date);

    }
    return dateUTC.local().format('MMM')
      .replace('.', '');
  }
  eventLink(event: any) {
    return ['/events/profile', event.id];
    if (this.logged) {
    }
    return ['/block', this.block.id];
  }
}
