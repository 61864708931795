<a class="{{ logoClass }}" routerLink="/home">
  <img
    [src]="logo.small"
    [srcset]="logo | srcset"
    width="200"
    alt="logo"
    decoding="async"
    loading="lazy"
  />
</a>
