import {Component, Input, OnInit} from '@angular/core';
import {InitProvider} from '../../../init-provider';

@Component({
  selector: 'app-theme-dark-logo',
  templateUrl: './ThemeDarkLogo.component.html',
  styleUrls: ['./ThemeDarkLogo.component.scss']
})
export class ThemeDarkLogoComponent implements OnInit {

  @Input() logoClass: any;

  public logo;

  constructor(initProvider: InitProvider) {
    this.logo = initProvider.getSettings().shadow_logo;
  }

  ngOnInit() {
  }
}
