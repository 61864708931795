import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { InitProvider } from "src/app/init-provider";
declare const Calendly: any;
@Component({
  selector: "app-appointment-setter",
  templateUrl: "./appointment-setter.component.html",
  styleUrls: ["./appointment-setter.component.scss"],
})
export class AppointmentSetterComponent implements OnInit, AfterViewInit  {
  @Input() infoLanding: any = {};
  company: any;
  public videoLoaded = false;
  textClean:any  = ''
  constructor(
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    public initProvider: InitProvider
  ) {
    this.company = initProvider.getSettings();
  }
  ngOnInit(): void {
    // Calendly.initInlineWidget({
    //   url: this.infoLanding?.data_json?.appointment_calendly_code,
    //   parentElement: document.querySelector('.calendly-inline-widget'),
    // });
    this.textClean = this.sanitizer.bypassSecurityTrustHtml(this.infoLanding?.title)
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
    const url = this.infoLanding?.data_json?.appointment_calendly_code;
    const element = document.querySelector('.calendly-inline-widget');
    element.setAttribute('data-url', url);
    Calendly.initInlineWidget({ url: url, parentElement: element });
  }
  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return "";
    }
    results = url.match("[\\?&]v=([^&#]*)");
    video = results === null ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/" + video
    );
  }
  openVideoModal(videoID) {
    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  initializeVideoPlayer(videoID) {
    this.videoLoaded = true;
    $("#modal-video").on("hide.bs.modal", () => {
      this.videoLoaded = false;
      this.changeDetectorRef.detectChanges();
    });
  }
}
