import {Injectable} from '@angular/core';
import {CompanyModel} from '../Models/CompanyModel';
import {InitProvider} from '../init-provider';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  company: CompanyModel;
  course_text: string;
  lesson_text: string;
  course_text_s: string;
  lesson_text_s: string;
  course_name_menu:string;
  digital_events_text: string;
  presential_events_text: string;
  events_text: string;
  show_lesson_text: boolean;
  show_btn: boolean;
  hideFreePlanInHome: boolean;
  viewPlansLoggedOut: boolean;

  constructor(
    initProvider: InitProvider
  ) {
    this.company = initProvider.getSettings();
    this.courseTexts();
  }

  configurations() {
    const json = JSON.parse(this.company.faq_json);
    if (json) {
      this.hideFreePlanInHome = json.hide_free_plan_in_home;
      this.viewPlansLoggedOut = json.view_plans_logout;
    }
  }

  courseTexts(): { events_text:string,presential_events_text:string,digital_events_text:string, lesson_text_s: string; lesson_text: string; course_text: string; show_lesson_text: boolean; course_text_s: string } {
    const json = JSON.parse(this.company.faq_json);
    const company_language = this.company.language;
    this.show_lesson_text = json.configuration ? json.configuration.show_lessons_in_courses : 'true';
    if (json.texts) {
      if (company_language === 'en') {
        this.course_text = json.texts.course_en ? json.texts.course_en : 'course';
        this.lesson_text = json.texts.lesson_en ? json.texts.lesson_en : 'unit';
        this.course_text_s = json.texts.courses_en ? json.texts.courses_en : 'courses';
        this.lesson_text_s = json.texts.lessons_en ? json.texts.lessons_en : 'unit';
        
        this.digital_events_text = json.texts.menu_digital_events_en ? json.texts.menu_digital_events_en : 'Digital events';
        this.presential_events_text = json.texts.menu_presential_events_en ? json.texts.menu_presential_events_en : 'Presential events';
        this.events_text = json.texts.events_en ? json.texts.events_en : 'Events';
        this.course_name_menu = json.texts.menu_courses_en ? json.texts.menu_courses_en : null;
        
        
      }
      if (company_language === 'es') {
        this.course_text = json.texts.course_es ? json.texts.course_es : 'Curso';
        this.lesson_text = json.texts.lesson_es ? json.texts.lesson_es : 'Unidad';
        this.course_text_s = json.texts.courses_es ? json.texts.courses_es : 'Cursos';
        this.lesson_text_s = json.texts.lessons_es ? json.texts.lessons_es : 'Unidades';
        this.digital_events_text = json.texts.menu_digital_events_es ? json.texts.menu_digital_events_es : 'Digital events';
        this.presential_events_text = json.texts.menu_presential_events_es ? json.texts.menu_presential_events_es : 'Presential events';
        this.events_text = json.texts.events_es ? json.texts.events_es : 'Events';
        this.course_name_menu = json.texts.menu_courses_es ? json.texts.menu_courses_es : null;


      }
      if (company_language === 'it') {
        this.course_text = json.texts.course_it ? json.texts.course_it : 'Course';
        this.lesson_text = json.texts.lesson_it ? json.texts.lesson_it : 'Unit';
        this.course_text_s = json.texts.courses_it ? json.texts.courses_it : 'Courses';
        this.lesson_text_s = json.texts.lessons_it ? json.texts.lessons_it : 'Unit';
        this.digital_events_text = json.texts.menu_digital_events_it ? json.texts.menu_digital_events_it : 'Digital events';
        this.presential_events_text = json.texts.menu_presential_events_it ? json.texts.menu_presential_events_it : 'Presential events';
        this.events_text = json.texts.events_it ? json.texts.events_it : 'Events';
        this.course_name_menu = json.texts.menu_courses_it ? json.texts.menu_courses_it : null; //este es null ya que si no viene utiliza la opcion de course_text

      }
    }

    console.log('this.course_name_menu',this.course_name_menu)

    return {
      course_text: this.course_text,
      lesson_text: this.lesson_text,
      course_text_s: this.course_text_s,
      lesson_text_s: this.lesson_text_s,
      show_lesson_text: this.show_lesson_text,
      digital_events_text: this.digital_events_text,
      presential_events_text: this.presential_events_text,
      events_text:this.events_text

    };
  }
}
