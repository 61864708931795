<div class="courseItem">
  <div class="itemImage hover-shadow" (click)="goToProfile(slider)" [ngStyle]="{'cursor': slider?.url ? 'pointer' : 'default'}">
 
      <img
        [src]="slider?.image.large"
        onerror="this.src = '/assets/images/mootiva/logo-dark.png'"
      />
 
  </div>
  <div class="itemDetails mt-2">
    <h4
      class="text-secondary"
      *ngIf="slider.title"
    >
      {{ slider.title }}
    </h4>

    <p
      class="itemTitle hover-text-shadow textFont mb-2"
      (click)="goToProfile(slider)"
      [innerHTML]="slider?.description"
    ></p>
    <div class="row">
       

       
    </div>
  </div>
</div>
