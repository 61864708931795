import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-stars",
  templateUrl: "./stars.component.html",
  styleUrls: ["./stars.component.scss"],
})
export class StarsComponent implements OnInit {
  @Input() stars: number = 0;

  get starsArray(): number[] {
    return new Array(Number(this.stars));
  }
  ngOnInit(): void {}
}
