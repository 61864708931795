<div *ngIf="loading" class="row justify-content-center">
  <div class="col-auto">
    <div class="loader-wrapper text-center m-5">
      <div><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>
    </div>
  </div>
</div>
<ng-container *ngIf="!loading">
  <a
    *ngIf="whatsapp_show_icon && whatsapp_number && false"
    target="_blank"
    [attr.href]="'https://wa.me/' + whatsapp_number"
    class="btn btn-round btn-fab btn-fab__wapp"
  >
    <img
      src="assets/images/whatsapp.png"
      alt="whatsapp"
      loading="lazy"
      decoding="async"
      class="img_icon_wp"
    />
  </a>
  <ng-container *ngIf="type == 'Appointment Setter'">
    <app-appointment-setter
      [infoLanding]="infoLanding"
    ></app-appointment-setter>
  </ng-container>
  <ng-container *ngIf="type == 'Lead Magnet'">
    <app-lead-magnet [infoLanding]="infoLanding"></app-lead-magnet>
  </ng-container>
  <ng-container *ngIf="type == 'High ticket'">
    <app-high-ticket [infoLanding]="infoLanding"></app-high-ticket>
  </ng-container>
  <ng-container *ngIf="type == 'Landing'">
    <app-landing-page [infoLanding]="infoLanding"></app-landing-page>
  </ng-container>
</ng-container>
