export const environment = {
  production: true,
  apiUrl: 'https://admin.app.mootiva.me/api/v1/',
  baseUrl: 'https://admin.app.mootiva.me/',
  apiKeyMap: 'AIzaSyABNf-IQEYMiot3cFI_Ele5uW8ig8DW8FA',
//  apiKeyMap: 'AIzaSyBMDhDxtsJolCrpTo5EsLtojSomOzVhqmo',
  paymentsUrl: 'https://walleq.io/',
  auth: {
    clientSecret: 'xGbAhWL960HycCoboFNvKdhpWJxnLdxIs55Gjn95',
    clientId: '2'
  }
};

