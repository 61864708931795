import {Component, Input, OnInit} from '@angular/core';
import {BlockModel} from '../../Interfaces/BlockModel';
import {CompanyModel} from 'src/app/Models/CompanyModel';
import {CompanyService} from '../../Services/company.service';
import { InitProvider } from 'src/app/init-provider';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-block-plans',
  templateUrl: './block-plans.component.html',
  styleUrls: ['./block-plans.component.scss']
})
export class BlockPlansComponent implements OnInit {
  @Input() block: BlockModel;
  @Input() company: CompanyModel;
  @Input() logged: boolean;
  hideFreePlanInHome: boolean = false;
  viewPlansLoggedOut: boolean;
  subtitleText:any;


  constructor( companyService: CompanyService,    
    protected sanitizer: DomSanitizer,private initProvider: InitProvider) {
    this.viewPlansLoggedOut = companyService.viewPlansLoggedOut;
  }

  ngOnInit(): void {
    this.company = this.initProvider.getSettings();
    const json = JSON.parse(this.company?.faq_json);
    if (json && json?.hide_free_plan_in_home) {
      this.hideFreePlanInHome = true;
    }
    if (this.block.subtitle?.substring(0, 3)=='<p>'){ 
      const restoDelTexto = this.block.subtitle?.substring(3);
      this.subtitleText=this.sanitizer.bypassSecurityTrustHtml('<p style="color:'+this.block.secondary_color+';">'+ restoDelTexto);
    }else{
      this.subtitleText=this.block.subtitle;
    }
  }

  hasTitleLink(){
    return this.block.title_url?true:false;
  }
  gotToLink(){
    if (this.block.title_url){
      window.location.href = this.block.title_url;
    }
  }
  

}
