import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { InitProvider } from "src/app/init-provider";
import { AuthService } from "src/app/Services/Auth/auth.service";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit, OnChanges {
  faqs = [];
  @Input() infoLanding: any = {};
  highlights: any = [];

  highlighted: any = [];
  learn: any = [];
  testimonials: any = [];
  featuredItems: any = [];
  constructor(
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public initProvider: InitProvider
  ) {
    const company = this.initProvider.getSettings();

    const json = JSON.parse(company.faq_json);
    if (json.faqs) {
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.featuredItems = [
      {
        title: this.infoLanding.data_json.featured_item_1,
      },
      {
        title: this.infoLanding.data_json.featured_item_2,
      },
      {
        title: this.infoLanding.data_json.featured_item_3,
      },
      {
        title: this.infoLanding.data_json.featured_item_4,
      },
      {
        title: this.infoLanding.data_json.featured_item_5,
      },
    ];
    this.highlights = [
      {
        title: this.infoLanding.data_json.highlight_1_title,
        description: this.infoLanding.data_json.highlight_1_description,
      },
      {
        title: this.infoLanding.data_json.highlight_2_title,
        description: this.infoLanding.data_json.highlight_2_description,
      },
      {
        title: this.infoLanding.data_json.highlight_3_title,
        description: this.infoLanding.data_json.highlight_3_description,
      },
    ];
    this.highlighted = [
      {
        title: this.infoLanding.data_json.highlighted_title_1,
        description: this.infoLanding.data_json.highlighted_description_1,
      },
      {
        title: this.infoLanding.data_json.highlighted_title_2,
        description: this.infoLanding.data_json.highlighted_description_2,
      },
      {
        title: this.infoLanding.data_json.highlighted_title_3,
        description: this.infoLanding.data_json.highlighted_description_2,
      },
      {
        title: this.infoLanding.data_json.highlighted_title_4,
        description: this.infoLanding.data_json.highlighted_description_4,
      },
      {
        title: this.infoLanding.data_json.highlighted_title_5,
        description: this.infoLanding.data_json.highlighted_description_5,
      },
    ];

    this.learn = [
      {
        title: this.infoLanding.data_json.sub_title_learn_1,
        description: this.infoLanding.data_json.sub_description_learn_1,
      },
      {
        title: this.infoLanding.data_json.sub_title_learn_2,
        description: this.infoLanding.data_json.sub_description_learn_2,
      },
      {
        title: this.infoLanding.data_json.sub_title_learn_3,
        description: this.infoLanding.data_json.sub_description_learn_3,
      },
      {
        title: this.infoLanding.data_json.sub_title_learn_4,
        description: this.infoLanding.data_json.sub_description_learn_4,
      },
    ];

    const faqKeys = [1, 2, 3, 4, 5];
    faqKeys.forEach((key) => {
      const question = this.infoLanding.data_json[`faq_${key}_question`];
      const answer = this.infoLanding.data_json[`faq_${key}_answer`];

      if (question && answer) {
        this.faqs.push({
          title: question,
          content: answer,
        });
      }
    });

    if (this.faqs) {
      this.faqs = this.faqs.map((faq) => {
        let actual = faq;
        actual.collapsed = false;
        return actual;
      });
    }

    this.testimonials = [
      {
        name: this.infoLanding.data_json.testimonial_1_name,
        stars: this.infoLanding.data_json.testimonial_1_stars,
        text: this.infoLanding.data_json.testimonial_1_text,
        image: this.infoLanding.testimonials_image_01.small,
      },
      {
        name: this.infoLanding.data_json.testimonial_2_name,
        stars: this.infoLanding.data_json.testimonial_2_stars,
        text: this.infoLanding.data_json.testimonial_2_text,
        image: this.infoLanding.testimonials_image_02.small,
      },
      {
        name: this.infoLanding.data_json.testimonial_3_name,
        stars: this.infoLanding.data_json.testimonial_3_stars,
        text: this.infoLanding.data_json.testimonial_3_text,
        image: this.infoLanding.testimonials_image_03.small,
      },
    ];
  }

  ngOnInit(): void {}
  setAllClosed(title) {
    this.faqs = this.faqs.map((faq) => {
      return { ...faq, collapsed: faq.title == title };
    });
  }
}
