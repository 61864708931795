import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';

import 'rxjs/add/operator/map';
import {environment} from '../environments/environment';
import {CompanyModel} from './Models/CompanyModel';

@Injectable()
export class InitProvider {

  private settings: CompanyModel;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public getSettings() {
    return this.settings;
  }

  load() {
    const domain = window.location.href.split('/')[2];
    return new Promise((resolve, reject) => {
      let apiUrl = environment.apiUrl;
      // if (document.location.protocol === 'https:') {
      //   apiUrl = environment.apiUrl.replace('http://', 'https://');
      // } else if (document.location.protocol === 'http:') {
      //   apiUrl = environment.apiUrl.replace('https://', 'http://');
      // }
      this.http
        .get(apiUrl + 'public/settings?domain=' + domain)
        .subscribe(response => {
          this.settings = response['data'];
          //  console.log( this.settings );
          resolve(true);
        });
    });
  }
}
